/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Input } from "components";
import "./style.css";
import arrowDown from "assets/common/arrow_down.svg";
import { validators } from "helper/validator";

const roles = ["Guest", "Member", "Admin"];
const InviteMemberModal = ({ cancel, invite, editTeamMember }) => {
	const [rolePopupVisible, setRolePopupVisible] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState(roles[0]);
	const [errorMsg, setErrorMsg] = useState({ name: null, email: null });
	useEffect(() => {
		if (editTeamMember) {
			setName(editTeamMember.name);
			setEmail(editTeamMember.email);
			setRole(editTeamMember.role);
		}
	}, [editTeamMember]);

	const selectRole = (value) => {
		setRole(value);
		setRolePopupVisible(false);
	};

	const submitInvitation = () => {
		const errorObj = {
			email: validators.required(email) || validators.email(email),
			name: validators.required(name) || validators.minLength(name, 3)
		};
		setErrorMsg(errorObj);
		if (Object.values(errorObj).every((value) => !value))
			invite({
				name,
				email,
				role,
				index: editTeamMember && editTeamMember.index
			});
	};

	return (
		<>
			<div className="low-opacity"></div>
			<div className="invite-team">
				<h1 className="title">
					{editTeamMember ? "Edit" : "Invite"} Team Member
				</h1>
				<Input
					label="Full Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					errorMsg={errorMsg.name}
				/>
				<Input
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					errorMsg={errorMsg.email}
				/>

				<div className="group-832">
					<div className="full-name inter-black-white-18px">
						Team Member Role
					</div>
					<div
						className="role-input selected-role"
						onClick={() => setRolePopupVisible(true)}
					>
						<div className="item">{role}</div>
						<img src={arrowDown} />
					</div>
				</div>
				<div className="btns-container">
					<button className="popup-btn" onClick={cancel}>
						CANCEL
					</button>
					<button
						className="popup-btn blue-btn"
						onClick={() => submitInvitation()}
					>
						Save
					</button>
				</div>
				{rolePopupVisible && (
					<div className="role-modal">
						{roles.map((item, i) => (
							<div key={i} onClick={() => selectRole(item)}>
								{item}
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default InviteMemberModal;
