import dash1 from "assets/dashboard/sample-data/dash1.png";
import dash2 from "assets/dashboard/sample-data/dash2.png";
import dash3 from "assets/dashboard/sample-data/dash3.png";
import dash4 from "assets/dashboard/sample-data/dash4.png";
import dash5 from "assets/dashboard/sample-data/dash5.png";
import dash6 from "assets/dashboard/sample-data/dash6.png";
import dash7 from "assets/dashboard/sample-data/dash7.png";
import dash8 from "assets/dashboard/sample-data/dash8.png";
import dash9 from "assets/dashboard/sample-data/dash9.png";
import dash10 from "assets/dashboard/sample-data/dash10.png";
import dash11 from "assets/dashboard/sample-data/dash11.png";
import dash12 from "assets/dashboard/sample-data/dash12.png";
import dash13 from "assets/dashboard/sample-data/dash13.png";
import dash14 from "assets/dashboard/sample-data/dash14.png";
import dash15 from "assets/dashboard/sample-data/dash15.png";
import dash16 from "assets/dashboard/sample-data/dash16.png";
import dash17 from "assets/dashboard/sample-data/dash17.png";
import dash18 from "assets/dashboard/sample-data/dash18.png";
import dash19 from "assets/dashboard/sample-data/dash19.png";
import dash20 from "assets/dashboard/sample-data/dash20.png";

export const popularData = [
	{
		title: "Getting Started",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
		img: dash1
	},
	{
		title: "The Future of Synthetic Content",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
		img: dash2
	},
	{
		title: "Short cuts",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
		img: dash3
	},
	{
		title: "The Future of Synthetic Content",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
		img: dash4
	}
];

export const data = [
	{
		sectionTitle: "Face Morphing",
		background: {
			deg: "94.51deg",
			first: "#A3A5FA 1.82%",
			last: "#6466DF 68.67%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash5
			},
			{
				title: "Tutorial: Face Segmentation ",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash6
			}
		]
	},
	{
		sectionTitle: "Color Swapping",
		background: {
			deg: "180deg",
			first: "#ffdc84 0%",
			last: "#f1ff52 100%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash7
			},
			{
				title: "Tutorial: Colors that Pop",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash8
			}
		]
	},
	{
		sectionTitle: "Facial Landmarks",
		background: {
			deg: "94.51deg",
			first: "#eebc8f 1.82%",
			last: "#ff8a00 68.67%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash9
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash10
			}
		]
	},
	{
		sectionTitle: "Image Blending",
		background: {
			deg: "152.3deg",
			first: "#fa00ff 10.49%",
			last: "#8a0c8c 106.96%",
			rgb: "rgba(255, 255, 255, 0) 106.96%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash11
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash12
			}
		]
	},
	{
		sectionTitle: "2D -> 3D",
		background: {
			deg: "180deg",
			first: "#fc3030 0%",
			last: "#ff52ba 100%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash13
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash14
			}
		]
	},
	{
		sectionTitle: "Background Removal",
		background: {
			deg: "94.51deg",
			first: "#8fe8ee 1.82%",
			last: "#09d3ff 68.67%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash15
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash16
			}
		]
	},
	{
		sectionTitle: "File Conversion",
		background: {
			deg: "94.51deg",
			first: "#91ee8f 1.82%",
			last: "#30d321 68.67%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash17
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash18
			}
		]
	},
	{
		sectionTitle: "AI Auto Enhance",
		background: {
			deg: "315.39deg",
			first: "#1829ee 0%",
			last: "#7a85ff 114.85%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash2
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash19
			}
		]
	},
	{
		sectionTitle: "Text Recognition",
		background: {
			deg: "94.51deg",
			first: "#8feec4 1.82%",
			last: "#21d398 68.67%"
		},
		sectionContent: [
			{
				title: "Getting Started",
				icon: "book",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et",
				img: dash20
			},
			{
				title: "Tutorial",
				icon: "learn",
				content:
					"Lets do a facial segementaton on an image to understand what things can be editted or morphed by our AI tools.",
				img: dash20
			}
		]
	}
];

export const pyramidData = {
	1: {
		users: 15,
		media: 19,
		jobs: 25,
		visitors: 5232,
		customers: 123,
		calls: 543,
		expenses: 12543,
		usage: 30,
		title: "Face Morphing"
	},
	2: {
		users: 11,
		media: 12,
		jobs: 35,
		visitors: 7242,
		customers: 163,
		calls: 943,
		expenses: 17543,
		usage: 20,
		title: "Color Swapping"
	},
	3: {
		users: 29,
		media: 39,
		jobs: 35,
		visitors: 3232,
		customers: 323,
		calls: 343,
		expenses: 19543,
		usage: 10,
		title: "Text Recognition"
	},
	4: {
		users: 23,
		media: 22,
		jobs: 35,
		visitors: 9232,
		customers: 623,
		calls: 123,
		expenses: 10443,
		usage: 40,
		title: "Facial Landmarks"
	},
	5: {
		users: 31,
		media: 22,
		jobs: 27,
		visitors: 5632,
		customers: 223,
		calls: 213,
		expenses: 16543,
		usage: 50,
		title: "Image Blending"
	},
	6: {
		users: 5,
		media: 9,
		jobs: 252,
		visitors: 532,
		customers: 23,
		calls: 43,
		expenses: 10543,
		usage: 60,
		title: "File Conversion"
	}
};

export const accountEarningsData = [
	{
		revenue: 1000,
		expense: 0,
		margin: 0
	},
	{
		revenue: 0,
		expense: 20000,
		margin: 20000
	},
	{
		revenue: 20000,
		expense: 10000,
		margin: 40000
	},
	{
		revenue: 50000,
		expense: 30000,
		margin: 10000
	},
	{
		revenue: 20000,
		expense: 80000,
		margin: 70000
	},
	{
		revenue: 70000,
		expense: 60000,
		margin: 30000
	},
	{
		revenue: 100000,
		expense: 100000,
		margin: 10000
	}
];

export const accountEarningsLines = [
	{
		key: "expense",
		color: "#1AD697",
		active: true
	},
	{
		key: "revenue",
		color: "#1F50FF",
		active: true
	},
	{
		key: "margin",
		color: "#FF0000",
		active: true
	}
];

export const apiUsageData = [
	{
		faceMorhing: 100,
		colorSwapping: 0,
		textRec: 0,
		facialLand: 0,
		imageBlending: 0,
		fileConv: 0,
		photoModel: 0,
		backgroundRem: 0,
		aiEnhance: 0
	},
	{
		faceMorhing: 50,
		colorSwapping: 30,
		textRec: 160,
		facialLand: 140,
		imageBlending: 200,
		fileConv: 40,
		photoModel: 160,
		backgroundRem: 100,
		aiEnhance: 100
	},
	{
		faceMorhing: 20,
		colorSwapping: 80,
		textRec: 70,
		facialLand: 30,
		imageBlending: 109,
		fileConv: 100,
		photoModel: 30,
		backgroundRem: 10,
		aiEnhance: 140
	},
	{
		faceMorhing: 50,
		colorSwapping: 30,
		textRec: 160,
		facialLand: 140,
		imageBlending: 100,
		fileConv: 40,
		photoModel: 160,
		backgroundRem: 100,
		aiEnhance: 100
	},
	{
		faceMorhing: 20,
		colorSwapping: 80,
		textRec: 70,
		facialLand: 30,
		imageBlending: 109,
		fileConv: 100,
		photoModel: 30,
		backgroundRem: 10,
		aiEnhance: 140
	},
	{
		faceMorhing: 70,
		colorSwapping: 60,
		textRec: 30,
		facialLand: 30,
		imageBlending: 60,
		fileConv: 100,
		photoModel: 200,
		backgroundRem: 30,
		aiEnhance: 0
	},
	{
		faceMorhing: 100,
		colorSwapping: 10,
		textRec: 10,
		facialLand: 90,
		imageBlending: 180,
		fileConv: 200,
		photoModel: 30,
		backgroundRem: 10,
		aiEnhance: 100
	}
];

export const apiUsageLines = [
	{
		key: "faceMorhing",
		color: "#A3A5FA",
		active: true,
		title: "Face Morphing"
	},
	{
		key: "colorSwapping",
		color: "#FFDC84",
		active: true,
		title: "Color Swaping"
	},
	{
		key: "textRec",
		color: "#8FEEC3",
		active: true,
		title: "OCR"
	},
	{
		key: "facialLand",
		color: "#EEBC8F",
		active: true,
		title: "Facial Landmark"
	},
	{
		key: "imageBlending",
		color: "#FA00FF",
		active: true,
		title: "Image Blending"
	},
	{
		key: "fileConv",
		color: "#91EE8F",
		active: true,
		title: "File Conversion"
	},
	{
		key: "photoModel",
		color: "#FC3030",
		active: true,
		title: "2D -> 3D"
	},
	{
		key: "backgroundRem",
		color: "#8FE8EE",
		active: true,
		title: "Background Removal"
	},
	{
		key: "aiEnhance",
		color: "#1829EE",
		active: true,
		title: "Auto Enhancement"
	}
];
