/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.css";

const EmailAccess = ({ onClick, onChange, email }) => {
    return (
        <div className="email-access">
            <div className="group">
                <input
                    className="enter-your-email-address sfprodisplay-black-black-16px"
                    placeholder="enter your email address"
                    onChange={onChange}
                    value={email}
                />
            </div>
            <div className="emailinputbutton" onClick={() => onClick(email)}>
                <div className="early-access sfprodisplay-black-white-20px">Early Access</div>
            </div>
        </div>
    );
};

export default EmailAccess;
