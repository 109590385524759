/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../Dropdown/Dropdown";
import "./style.css";
import logoWord from "assets/common/logo-word.svg";
import nick from "assets/common/nick.svg";
import dashboard from "assets/common/main-pages/dashboard.svg";
import users from "assets/common/main-pages/users.svg";
import browseAi from "assets/common/main-pages/browseAi.svg";
import jobs from "assets/common/main-pages/jobs.svg";
import data from "assets/common/main-pages/data.svg";
import media from "assets/common/main-pages/media.svg";

const options = [
	{
		key: 0,
		value: "Primary 1"
	},
	{
		key: 1,
		value: "Design Pro 2"
	},
	{
		key: 2,
		value: "General Work"
	},
	{
		key: 3,
		value: "Adidas Project"
	},
	{
		key: 4,
		value: "Personal"
	},
	{
		key: 5,
		value: "Marketing Media"
	}
];
const pages = [
	{ url: "/app/dashboard", icon: dashboard, name: "Dashboard" },
	{ url: "/app/browse-ai", icon: browseAi, name: "Browse AI" },
	{ url: "/app/media", icon: media, name: "Media" },
	{ url: "/app/jobs", icon: jobs, name: "Jobs" },
	{ url: "/app/users", icon: users, name: "Users" }
];

const mobilePages = [
	{ url: "/app/dashboard", icon: dashboard, name: "Dashboard" },
	{ url: "/app/media", icon: media, name: "Media" },
	{ url: "/app/data", icon: data, name: "Data" },
	{ url: "/app/browse-ai", icon: browseAi, name: "Browse AI" }
];
const AppLeftnav = () => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	const history = useHistory();
	const [value, setValue] = useState(options[0].value);
	const [subPagesModal, setSubPagesModal] = useState(false);
	const [activePage, setActivePage] = useState(window.location.pathname);

	const goTo = (selected, subPage) => {
		if (!subPage) history.push(selected);
		else history.push(subPage);

		if (subPagesModal) setSubPagesModal(false);
		setActivePage(selected);
	};

	return (
		<div className="appleftnav">
			<Link to="dashboard">
				<div className="logo-container hidden-sm">
					<img className="ai" src={logoWord} />
					<h1 className="title valign-text-middle">media</h1>
				</div>
			</Link>
			<div className="brand">
				<div className="img-container">
					<img className="img" src={nick} />
				</div>
				<div className="the-nike-company hidden-sm">The Nike Company</div>
			</div>
			<Dropdown
				style={{ marginTop: 15 }}
				items={options}
				value={value}
				handleChange={(e) => setValue(e.target.value)}
			/>

			{(isMobile ? mobilePages : pages).map((item, i) => (
				<div key={i}>
					<div
						className={`browse-btn ${
							activePage === item.url ? "browse-btn-active" : ""
						}`}
						onClick={() =>
							item.url !== "/app/data" ? goTo(item.url) : setSubPagesModal(true)
						}
					>
						<img className="item" src={item.icon} />
						<div
							className="
								content
								valign-text-middle
								sfprodisplay-normal-white-14px
							"
						>
							{item.name}
						</div>
					</div>
					{subPagesModal && item.url === "/app/data" && (
						<div className="data-popup">
							<div onClick={() => goTo("/app/data", "jobs")}>
								<img className="frame" src={jobs} />
								<p>Jobs</p>
							</div>
							<div onClick={() => goTo("/app/data", "users")}>
								<img className="frame" src={users} />
								<p>Users</p>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default AppLeftnav;
