/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SettingDropdown from "./components/SettingDropdown/SettingDropdown";
import DocsDropdown from "./components/DocsDropdown/DocsDropdown";
import SearchInput from "components/SearchInput/SearchInput";
import "./style.css";
import logoWord from "assets/common/logo-word.svg";
import headerMobile from "assets/common/header_mobile_icon.svg";
import arrowDown from "assets/common/arrow_down.svg";
import create from "assets/common/create_icon.svg";
import docHelp from "assets/common/docs_help_icon.svg";

const AppHeader = () => {
	const user = useSelector((state) => state.auth.user);
	const [settingVisible, setSettingVisible] = useState(false);
	const [createVisible, setCreateVisible] = useState(false);
	const [docsVisible, setDocsVisible] = useState(false);

	return (
		<div>
			<div className="header">
				<div className="appheader">
					<SearchInput style={{ width: 428 }} />
					<div
						className="group-8318"
						onMouseLeave={() => setDocsVisible(false)}
						onMouseEnter={() => setDocsVisible(true)}
					>
						<img className="group-7955" src={docHelp} />
						<div className="dashboard-alpha-1">DOCS + HELP</div>
						<img className="rectangle-20-3" src={arrowDown} />
						{docsVisible && (
							<DocsDropdown onClick={() => setDocsVisible(!docsVisible)} />
						)}
					</div>
					<div
						className="creeatebutton"
						onMouseLeave={() => setCreateVisible(false)}
						onMouseEnter={() => setCreateVisible(true)}
					>
						<div className="overlap-group1-2">
							<img className="group-8052" src={create} />
							<div className="create">Create</div>
						</div>
						{createVisible && (
							<DocsDropdown
								onClick={() => setCreateVisible(!createVisible)}
								create
							/>
						)}
					</div>
					<div
						className="group-8201"
						onMouseLeave={() => setSettingVisible(false)}
						onMouseEnter={() => setSettingVisible(true)}
					>
						<img className="rectangle-21" src={arrowDown} />
						<div className="group-7481">
							<img className="rectangle-1024" src={user?.picture} />
						</div>
						{settingVisible && (
							<SettingDropdown
								onClick={() => setSettingVisible(!settingVisible)}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="header-mobile">
				{docsVisible && (
					<DocsDropdown onClick={() => setDocsVisible(!docsVisible)} />
				)}

				<div className="group-46953">
					<img
						className="frame-8646"
						src={headerMobile}
						onClick={() => setDocsVisible(!docsVisible)}
					/>
					<Link to="dashboard" className="flex-container">
						<div className="valign-text-middle sfprodisplay-heavy-normal-white-32px">
							media
						</div>
						<img className="ai" src={logoWord} />
					</Link>
				</div>
				<div
					className="group-8201"
					onClick={() => setSettingVisible(!settingVisible)}
				>
					<img className="rectangle-21" src={arrowDown} />
					<div className="group-7481">
						<img className="rectangle-1024" src={user?.picture} />
					</div>
					{settingVisible && (
						<SettingDropdown
							onClick={() => setSettingVisible(!settingVisible)}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppHeader;
