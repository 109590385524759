/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Table } from "components";
import { data } from "./sample-data";
import { aiFilters, aiTypes } from "helper/constants";

const Jobs = () => (
	<Table
		data={data}
		filters={aiFilters}
		types={aiTypes}
		headers={[
			"Job ID",
			"Project Name",
			"Requestor ID",
			"Submitted Date",
			"Queue Number",
			"AI Used",
			"Credits Used",
			"Time Elapsed",
			"Details"
		]}
		mobileAttributes={["projectName", "req_id", "date"]}
	/>
);

export default Jobs;
