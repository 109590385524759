/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { data } from "./sample-data";
import "./style.css";
import arrowRight from "assets/common/arrow_right.svg";

const BrowseAi = () => {
    return (
        <div className="browse-ai">
            <div className="overlap-group11">
                <div className="flex-row-1">
                    {data.map((item, i) => (
                        <div key={i} className="desktop">
                            <img className="rectangle-1181" src={item.img} />
                            <div className="overlap-group6">
                                <div>
                                    <h1 className="amount-1 sfprodisplay-heavy-normal-white-28px">
                                        {item.title}
                                    </h1>
                                    <p className="text-6 sfprodisplay-regular-normal-white-14px">
                                        {item.content}
                                    </p>
                                </div>
                                <Link to="/landing/coming-soon">
                                    <div className="docbuttondesktop">
                                        <div
                                            className="
										docs
										valign-text-middle
										sfprodisplay-black-white-14px
										"
                                        >
											DOCS
                                        </div>
                                        <img
                                            className="group-3"
                                            src={arrowRight}
                                        />
                                    </div>
                                </Link>
                                <Link to="/landing/coming-soon">
                                    <div className="tryaibuttondesktop hidden-sm">
                                        <div className="overlap-group">
                                            <div
                                                className="
											try-ai
											valign-text-middle
											sfprodisplay-black-white-14px
										"
                                            >
												Try Ai
                                            </div>
                                            <img
                                                className="group-3-1"
                                                src={arrowRight}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BrowseAi;
