export const data = [
	{
		userId: "4563-001",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-002",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-003",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-004",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-005",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-006",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-007",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-008",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-009",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-0010",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-001",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-002",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-003",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-004",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-005",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-006",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-007",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-008",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-009",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-0010",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-001",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-002",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-003",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-004",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-005",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-006",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-007",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-008",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-009",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Face Morphing"],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		userId: "4563-0010",
		gpuRuntime: "1 min 23 secs",
		req_id: "user_4673",
		date: "09 - 08 - 2020",
		queue_no: 37,
		ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
		credit_used: 10,
		time_elapsed: 5.2
	}
];
