/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validators } from "helper/validator";
import { loginAction } from "store/actions/auth.action";
import { Checkbox } from "@mui/material";
import { Input } from "components";
import "./style.css";
import google from "assets/common/google.svg";
import github from "assets/common/github.svg";
import gitlab from "assets/common/gitlab.svg";

const Login = ({ setComponent, loginAuth0 }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [staySignedIn, setStaySignedIn] = useState(false);
	const [isPassChecked, setPassChecked] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMsg, setErrorMsg] = useState({ email: null, password: null });

	const login = () => {
		const errorObj = {
			email: validators.required(email) || validators.email(email),
			password: validators.required(password)
		};
		setErrorMsg(errorObj);

		if (Object.values(errorObj).every((value) => !value)) {
			dispatch(loginAction({}, () => history.push("/app/dashboard")));
		}
	};

	return (
		<div className="login">
			<div className="upper-section">
				<h2
					className="sfprodisplay-heavy-normal-white-32px hidden-md"
					style={{ marginBottom: 10, textAlign: "center" }}
				>
					Magic for Media
					<br /> Made Easy.
				</h2>
				<h2 className="sfprodisplay-heavy-normal-white-24px">Sign In</h2>
				<div className="login-with-container">
					<div className="login-with-item" onClick={() => loginAuth0()}>
						<img src={google} />
						<p className="sfprodisplay-bold-white-16px">Sign in with Google</p>
					</div>
					<div className="login-with-item" onClick={() => loginAuth0()}>
						<img src={github} />
						<p className="sfprodisplay-bold-white-16px">Sign in with GitHub</p>
					</div>
					<div className="login-with-item" onClick={() => loginAuth0()}>
						<img src={gitlab} />
						<p className="sfprodisplay-bold-white-16px">Sign in with GitLab</p>
					</div>
				</div>
			</div>
			<div className="lower-section">
				<div style={{ paddingLeft: 20 }}>
					<Input
						label="Email"
						placeholder="Email..."
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						errorMsg={errorMsg.email}
					/>
					<div className="show-pass">
						<div
							className="checkbox-container"
							onClick={() => setPassChecked(!isPassChecked)}
						>
							<p className="sfprodisplay-regular-normal-white-10px">
								Show Password
							</p>
							<Checkbox checked={isPassChecked} />
						</div>
						<Input
							label="Password"
							placeholder="Password..."
							type={isPassChecked ? "text" : "password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							errorMsg={errorMsg.password}
						/>
					</div>

					<div
						className="stay-signed"
						onClick={() => setStaySignedIn(!staySignedIn)}
					>
						<Checkbox checked={staySignedIn} />
						<p className="sfprodisplay-regular-normal-white-10px">
							Stay signed in
						</p>
					</div>
				</div>
				<div className="btns-container">
					<button className="btn" onClick={() => login()}>
						<p>Sign In</p>
					</button>
				</div>
				<div
					className="stay-signed sfprodisplay-regular-normal-white-10px"
					style={{ justifyContent: "flex-end", marginLeft: -35 }}
					onClick={() => setComponent(1)}
				>
					<p>
						Do not have an account?<span> &#160; &#160;Register</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
