import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Auth, SignInFlow } from "pages";
const AuthRoute = () => {
	return (
		<div style={{ height: "100vh" }}>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/signin-flow" component={SignInFlow} />
				<Route path="*" render={() => <Redirect to="/auth" />} />
			</Switch>
		</div>
	);
};

export default AuthRoute;
