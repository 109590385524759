import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useMediaQuery } from "react-responsive";
import "./style.css";

const Dropdown = ({ value, handleChange, items, style, styleClass, menuclassName }) => {
    const isMobile = useMediaQuery({ maxWidth: 800 });
    return (
        <FormControl className="formControl" style={style}>
            <Select
                value={isMobile ? "" : value}
                onChange={handleChange}
                IconComponent={ExpandMoreRoundedIcon}
                classes={{
                    select: `dropdown ${styleClass}`
                }}
                MenuProps={{ classes: { paper: menuclassName || "menu-top" } }}
            >
                {items.map(item => (
                    <MenuItem className={value === item.value ? "selected-item" : ""} key={item.key} value={item.value}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Dropdown;
