/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { aiFilters } from "helper/constants";
import SearchInput from "components/SearchInput/SearchInput";
import "./style.css";
import chat from "assets/common/chat_icon.svg";
import arrowRight from "assets/common/arrow_right.svg";
import upload from "assets/common/upload.svg";
import browseMedia from "assets/common/browse-media.svg";
import browse from "assets/common/browse.svg";

const DocsDropdown = ({ onClick, create }) => {
    return (
        <div className="large-menu" style={{ marginLeft: create && -570 }}>
            <div className="hidden-md">
                <SearchInput style={{ width: "80%", marginTop: 20, marginLeft: 15 }} />
                <div className="closeicon" onClick={onClick}>
                    <div></div>
                    <div></div>
                </div>
                <div className="keys-container">
                    <div className="flex-container">
                        <p className="sfprodisplay-heavy-normal-white-14px">Your API Keys</p>
                    </div>
                    <div className="flex-container">
                        <p className="sfprodisplay-bold-white-12px">Publishable Key:</p>
                        <p className="sfprodisplay-bold-white-12px">absndjfj_skdxjsJshdnjJhi39</p>
                    </div>
                    <div className="flex-container">
                        <p className="sfprodisplay-bold-white-12px">Secret key:</p>
                        <p className="sfprodisplay-bold-white-12px">absndjfj_skdxjsJshdnjJhi39</p>
                    </div>
                </div>
            </div>
            <div className="upper-section">
                <div className="left-section" onClick={onClick}>
                    <h2 className="sfprodisplay-heavy-normal-white-28px">AI Docs</h2>
                    <div className="left-section-content">
                        {aiFilters.map((item, i) => (
                            <Link to="/landing/coming-soon" key={i}>
                                <div className="item-container">
                                    <img src={item.icon} />
                                    <div>
                                        <h2 className="sfprodisplay-heavy-normal-white-16px">{item.name}</h2>
                                        <p className="sfprodisplay-bold-white-16px">{item.desc}</p>
                                    </div>
                                    <img className="arrow" src={arrowRight} />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="right-section hidden-sm">
                    <div className="flex-container">
                        <h2 className="sfprodisplay-heavy-normal-white-28px">{create ? "Media" : "Help"}</h2>
                        <div className="closeicon" onClick={onClick}>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div className="right-section-content" onClick={onClick}>
                        {!create ? (
                            <>
                                <div>
                                    <h2 className="sfprodisplay-bold-white-20px">Getting Started</h2>
                                    <p>Creating your App Token</p>
                                    <p>Manage your Account</p>
                                    <p>Manage your Account</p>
                                </div>

                                <div>
                                    <h2 className="sfprodisplay-bold-white-20px">Guides</h2>
                                    <p>Creating your App Token</p>
                                    <p>Manage your Account</p>
                                    <p>Manage your Account</p>
                                    <p>More....</p>
                                </div>
                            </>
                        ) : (
                            <div>
                                <div className="icon-container">
                                    <img src={browseMedia} />
                                    <p> Library</p>
                                </div>
                                <div className="icon-container">
                                    <img src={upload} />
                                    <p> Drag & Drop</p>
                                </div>
                                <div className="icon-container">
                                    <img src={browse} />
                                    <p> Browse</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {!create && (
                <div className="lower-section">
                    <h2 className="sfprodisplay-bold-white-20px hidden-md">Help</h2>
                    <div className="flex-container">
                        <img src={chat} />
                        <h2 className="sfprodisplay-bold-white-20px">Support</h2>
                    </div>
                    <div className="lower-section-content">
                        <p>Learning Center</p>
                        <p>Forums</p>
                        <p>Live Help</p>
                    </div>
                    <div className="hidden-md lower-section-content-2">
                        <div>
                            <h2 className="sfprodisplay-bold-white-16px">Getting Started</h2>
                            <p>Creating your App Token</p>
                            <p>Manage your Account</p>
                            <p>Manage your Account</p>
                        </div>

                        <div>
                            <h2 className="sfprodisplay-bold-white-16px">Guides</h2>
                            <p>Creating your App Token</p>
                            <p>Manage your Account</p>
                            <p>Manage your Account</p>
                            <p>More....</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocsDropdown;
