export const data = [
    {
        jobId: "01",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "02",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "03",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "04",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Face Morphing"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "05",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "06",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "07",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "8",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "09",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Face Morphing"],
        credit_used: 10,
        time_elapsed: 5.2
    },
    {
        jobId: "10",
        projectName: "1 min 23 secs",
        req_id: "job_4673",
        date: "09 - 08 - 2020",
        queue_no: 37,
        ai_used: ["Image Blending", "Face Morphing", "Facial Landmarks"],
        credit_used: 10,
        time_elapsed: 5.2
    }
];
