/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.css";
import {
    faceMorphing,
    colorSwapping,
    facialLandmarks,
    photoModel,
    backgroundRemoval,
    imageBlending,
    fileConversion,
    aiAuto,
    textRecognition
} from "helper/constants";
const VideoModal = ({ data, onClose }) => {
    return (
        <div className="video-modal animate-appear">
            <div className="rectangle-2354-XgzzMX">
                <img className="cover-bg-XgzzMX" src={data.img} />

                <div className="closemodalbutton-XgzzMX" onClick={onClose}>
                    <div className="rectangle-2285-xmmwye"></div>
                    <div className="rectangle-2286-xmmwye"></div>
                </div>

                <div className="line-55-XgzzMX"></div>
                <div className="group-8366-XgzzMX">
                    <div className="line-56-bFarxy"></div>
                    <div className="ellipse-488-bFarxy"></div>
                </div>
                <div className="rectangle-2355-XgzzMX"></div>
                <div className="x523-XgzzMX">5:23</div>
                <div className="line-57-XgzzMX"></div>
                <div className="line-58-XgzzMX"></div>
                <div className="administrator-XgzzMX sfprodisplay-normal-white-14px">Date</div>
                <div className="administrator-0qAw1K sfprodisplay-regular-normal-white-14px">
					09-08-2020
                </div>
                <div className="group-8367-XgzzMX">
                    <div className="administrator-JyPaL3 sfprodisplay-normal-white-14px">
						Details
                    </div>
                    <p className="administrator-ImHUJY">
						In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
						the visual form of a document or a typeface without relying on meaningful cont....
                    </p>
                </div>
                <div className="administrator-ohtexU sfprodisplay-normal-white-14px">Creator</div>
                <div className="administrator-3nWomT sfprodisplay-regular-normal-white-14px">
					@jonajohnson
                </div>
                <div className="administrator-Ljy6wH sfprodisplay-normal-white-14px">Earnings</div>
                <div className="administrator-mSbJFx sfprodisplay-regular-normal-white-14px">
					$1,119
                </div>
                <div className="dashboard-beta-XgzzMX">{data.img_title}</div>
                <div className="frame-8277-XgzzMX">
                    <div className="group-8395-TCKxxz">
                        <img className="vector-vcxgcm" src={faceMorphing} />
                    </div>
                </div>
                <div className="frame-8276-XgzzMX">
                    <div className="group-47015-pyeOsY">
                        <img className="group-46827-3r1hEK" src={colorSwapping} />
                    </div>
                </div>
                <div className="frame-47016-XgzzMX">
                    <div className="group-47019-ceEN5A">
                        <img className="union-Hc35PE" src={textRecognition} />
                    </div>
                </div>
                <div className="frame-7904-XgzzMX">
                    <img className="union-NMfOfB" src={facialLandmarks} />
                </div>
                <div className="frame-8279-XgzzMX">
                    <div className="group-47017-PHEVPA">
                        <img className="group-46831-Tbusei" src={imageBlending} />
                    </div>
                </div>
                <div className="frame-47015-XgzzMX">
                    <div className="group-8293-RsiTZ9">
                        <img className="union-zkVlHH" src={fileConversion} />
                    </div>
                </div>
                <div className="frame-47014-XgzzMX">
                    <div className="group-47020-axiH6w">
                        <img className="group-08SqN5" src={photoModel} />
                    </div>
                </div>
                <div className="frame-8290-XgzzMX">
                    <div className="group-47016-RD3Zx4">
                        <img className="subtract-HqaVXP" src={backgroundRemoval} />
                    </div>
                </div>
                <div className="frame-8289-XgzzMX">
                    <div className="group-47018-sw0TXz">
                        <img className="subtract-HqaVXP" src={aiAuto} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
