/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./style.css";
import calendarDate from "assets/common/calendar_date.svg";

const Filter = ({ onClick }) => {
    const [progressValue, setProgressValue] = useState(50);

    useEffect(()=>{},[progressValue])
    return (
        <div className="filter-container">
            <div className="overlap-group3">
                <div className="filter-flex-row">
                    <div className="filter-flex-col">
                        <div className="filter-text-1 sfprodisplay-medium-white-16px">Filters</div>
                        <div className="overlap-group4">
                            <div className="text sfprodisplay-medium-white-16px">Date Range</div>
                            <img className="calendar" src={calendarDate} />
                        </div>
                    </div>
                    <div className="filter-closemodalbutton" onClick={onClick}>
                        <div className="filter-overlap-group">
                            <div className="rectangle-2285"></div>
                            <div className="rectangle-2286"></div>
                        </div>
                    </div>
                </div>
                <div className="overlap-group6 sfprodisplay-medium-white-14px">
                    <div className="overlap-group1-2 phoneContainer">
                        <input className="phone valign-text-middle" type="date" defaultValue="2021-12-31" />
                    </div>
                    <div className="filter-overlap-group1 phoneContainer">
                        <input className="phone valign-text-middle" type="date" defaultValue="2022-12-31" />
                    </div>

                    <div className="text-2 valign-text-middle">-</div>
                </div>

                <div className="overlap-group5">
                    <div className="text sfprodisplay-medium-white-16px">Credits Used</div>
                    <div className="filter-number valign-text-middle">0</div>
                    <div className="filter-number-1 valign-text-middle">300</div>
                    <input
                        className="line-60"
                        type="range"
                        min="0"
                        max="300"
                        onChange={e => setProgressValue(parseInt(e.target.value, 10))}
                    />
                    <div className="frame-8355">
                        <div className="line-61"></div>
                        <div className="line-6"></div>
                        <div className="line-6"></div>
                        <div className="line-6"></div>
                        <div className="line-6"></div>
                        <div className="line-6"></div>
                        <div className="line-6"></div>
                    </div>
                    <div className="group-8416" style={{left:progressValue * 0.75}}>
                        <div className="group-8414">
                            <div className="filter-overlap-group-1">
                                <div className="filter-number-2 valign-text-middle" id="credit-value">
                                    {progressValue}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-overlap-group2">
                    <div className="filter valign-text-middle" onClick={onClick}>
						FILTER
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;
