import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AppRoute from "routes/appRoute";
import AuthRoute from "routes/authRoute";
import LandingRoute from "routes/landingRoute";
import { ScrollToTop } from "components";
import "./global.css";

const App = () => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="screen">
			<BrowserRouter>
				<ScrollToTop>
					<Switch>
						<Route path="/landing">
							<LandingRoute />
						</Route>
						{isAuthenticated && (
							<Route path="/app">
								<AppRoute />
							</Route>
						)}
						<Route path="*">
							<AuthRoute />
						</Route>
					</Switch>
				</ScrollToTop>
			</BrowserRouter>
		</div>
	);
};

export default App;
