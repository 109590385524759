import {
	USERS_REQUESTED,
	GET_USERS_SUCCESS,
	GET_USERS_FAILED
} from "../types/users.type";

const intialState = {
	data: null,
	loading: false,
	error: null
};

const usersReducer = (state = intialState, action) => {
	switch (action.type) {
		case USERS_REQUESTED:
			return { ...state, loading: true };

		case GET_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case GET_USERS_FAILED:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			};
		default:
			return state;
	}
};

export default usersReducer;
