/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";
import { MessagePopup, EmailAccess, BeforeAfterSlider } from "components";
import SelectLang from "./components/SelectLang/SelectLang";
import { aiFilters } from "helper/constants";
import { validateEmail } from "helper/validator";
import projectPreview from "assets/landing/project-preview.png";
import projectList from "assets/landing/project-list.png";
import editBefore from "assets/landing/edit-before.png";
import editAfter from "assets/landing/edit-after.png";
import jsIcon from "assets/common/js-icon.svg";
import jsText from "assets/common/js-text.svg";
import go from "assets/common/go.svg";
import ruby from "assets/common/ruby.svg";
import php from "assets/common/php.svg";
import pythonIcon from "assets/common/python-icon.svg";
import rest from "assets/common/rest_api.svg";
import graphql from "assets/common/graphql.svg";
import dashboard from "assets/landing/dashboard.png";
import simple from "assets/landing/features/simple-color.svg";
import secure from "assets/landing/features/secure-color.svg";
import scalable from "assets/landing/features/scalable-color.svg";
import fast from "assets/landing/features/fast-color.svg";
import debug from "assets/landing/features/debug-color.svg";
import sync from "assets/landing/features/sync-color.svg";
import cylinder from "assets/pricing/cylinder.png";
import supertoroid from "assets/pricing/supertoroid-2.png";
import roundcube from "assets/pricing/roundcube.png";
import transform from "assets/common/transform.svg";
const languages = [
	{
		title: "Javascript",
		icon: jsIcon
	},
	{
		title: "Python",
		icon: pythonIcon
	},
	{
		title: "Golang",
		icon: go
	},
	{
		title: "PHP",
		icon: php
	},
	{
		title: "Ruby",
		icon: ruby
	}
];
const Landing = () => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	const [selectedCode, setSelectedCode] = useState(0);
	const [activeFeature, setActiveFeature] = useState(0);
	const [messageContent, setMessageContent] = useState(false);
	const [openSelectLang, setOpenSelectLang] = useState(false);
	const [activeTab, setActiveTab] = useState(2);
	const [email, setEmail] = useState("");

	const selectLang = (email) => {
		if (!validateEmail(email)) setMessageContent("Email is invalid");
		else setOpenSelectLang(true);
	};

	const verifySelectedLang = (selected) => {
		if (!selected) return setOpenSelectLang(false);
		if (selected && !selected.includes(true))
			return setMessageContent("Please selected at least one language!");
		setOpenSelectLang(false);
		return setMessageContent(
			`Thank you for requesting early access: ${email} will be notified of updates!`
		);
	};
	return (
		<div className="landing">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(!messageContent)}
				/>
			)}

			{openSelectLang && (
				<SelectLang onClick={(selected) => verifySelectedLang(selected)} />
			)}
			<div className="overlap-group17 container">
				<img className="hidden-md cylinder" src={cylinder} />
				<img className="hidden-md roundcube" src={roundcube} />
				<img className="hidden-md supertoroid" src={supertoroid} />
				<div className="the-ai-api-for">
					The <span>AI API </span>for
				</div>
				<h1 className="title">Synthethic Media</h1>
				<div className="text-30" style={{ marginBlock: 20 }}>
					Conversions, Transformations, Face Morphs, Stickers
				</div>
				<div className="text-31 hidden-sm">
					In the future, all media will be personalized by AI for your users.
					<br />
					We make it easy for developers to deliver that.
				</div>
				<EmailAccess
					email={email}
					onClick={(email) => selectLang(email)}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className="overlap-group18 container  hidden-sm">
				<div className="text-5">Powerful AI Simplified</div>
				<div className="text-29">
					100k+ Hours of Supetrained AI&nbsp;&nbsp;in Minutes Not Months
				</div>
			</div>
			<div className="overlap-group15 container">
				<div className="flex-container hidden-md">
					<div
						className="overlap-group-9"
						style={{
							borderRadius: "30px 0px 0px 30px",
							marginRight: 5,
							opacity: activeTab === 1 ? 1 : 0.2
						}}
						onClick={() => setActiveTab(1)}
					>
						<div className="frame-46874">
							<div className="rectangle-2449"></div>
							<div className="rectangle-2450"></div>
							<div className="rectangle-245"></div>
							<div className="rectangle-245"></div>
							<div className="rectangle-2453"></div>
						</div>
						<div className="code-preview">Code Preview</div>
					</div>
					<div
						className="overlap-group-9"
						onClick={() => setActiveTab(2)}
						style={{
							opacity: activeTab === 2 ? 1 : 0.2
						}}
					>
						<div className="frame-46875">
							<img src={transform} />
						</div>
						<div className="transform">Transform</div>
					</div>
				</div>
				{(!isMobile || activeTab === 2) && (
					<div>
						<BeforeAfterSlider before={editBefore} after={editAfter} />
					</div>
				)}
				{(!isMobile || activeTab === 1) && (
					<div className="overlap-group8">
						<div className="flex-container">
							<div
								className={`frame-46846 ${
									selectedCode === 0 ? "selected-code" : ""
								}`}
								onClick={() => setSelectedCode(0)}
							>
								<img className="vector" src={jsIcon} />
								<img className="union-1" src={jsText} />
							</div>
							<div
								className={`frame-46846 ${
									selectedCode === 1 ? "selected-code" : ""
								}`}
								onClick={() => setSelectedCode(1)}
							>
								<svg
									width="100"
									height="44"
									viewBox="0 0 100 44"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M49.9988 22.1672C49.9988 19.3287 49.2067 17.8713 47.6226 17.7916C46.9921 17.7612 46.3763 17.8637 45.7776 18.1013C45.11 18.3457 44.8089 19.0214 44.8089 19.7324V22.5073C44.8089 24.3095 45.7083 26.4132 47.5072 26.3038C49.1676 26.1915 49.9988 24.814 49.9988 22.1672ZM51.9533 22.285C51.9533 23.7273 51.6223 24.9242 50.9567 25.8762C50.2147 26.9538 49.1864 27.5084 47.8716 27.5389C46.4604 27.5847 44.8091 28.4319 44.8091 29.844V31.5324C44.8091 32.1224 44.2226 32.5329 43.6682 32.3308C43.3327 32.2086 43.1094 31.8895 43.1094 31.5324V19.8695C43.1094 18.9559 43.3939 18.0156 44.1824 17.5541C45.19 16.9542 46.4134 16.6446 47.8524 16.6271C47.8634 16.627 47.874 16.6314 47.8817 16.6392C47.8894 16.6471 47.9001 16.6515 47.9111 16.6514C49.225 16.6394 50.2381 17.194 50.9509 18.3141C51.6187 19.3458 51.9533 20.6681 51.9533 22.285Z" />
									<path d="M62.3412 26.3269C62.341 26.3269 62.3409 26.3271 62.3409 26.3272C62.3409 28.2611 62.1516 29.6005 61.7721 30.3457C61.3908 31.0901 60.6657 31.6848 59.5943 32.1275C58.8624 32.4221 58.0807 32.6035 57.2497 32.6724C56.9753 32.6952 56.7306 32.5096 56.6622 32.2429C56.575 31.903 56.8034 31.5638 57.1498 31.5082C57.8352 31.398 58.3394 31.2879 58.6629 31.1774C59.4868 30.8928 60.0522 30.4557 60.3624 29.8701C60.6121 29.3936 60.7348 28.4832 60.7348 27.1363C60.7348 26.9252 60.5153 26.7848 60.3196 26.8639C59.2832 27.2831 58.2038 27.4914 57.0806 27.4914C56.2438 27.4914 55.5056 27.2234 54.8695 26.6837C54.1557 26.0966 53.7988 25.3517 53.7988 24.4495V17.8246C53.7988 17.4635 54.0271 17.1418 54.3679 17.0225C54.9204 16.8292 55.4983 17.2392 55.4983 17.8246V23.902C55.4983 24.679 55.7437 25.2774 56.2346 25.6974C56.7257 26.1173 57.3616 26.319 58.1407 26.3037C59.832 26.2656 60.6419 24.3634 60.6419 22.6717V17.7388C60.6419 17.2694 61.0224 16.889 61.4917 16.889C61.961 16.889 62.3414 17.2694 62.3414 17.7388V26.3267C62.3414 26.3268 62.3413 26.3269 62.3412 26.3269Z" />
									<path d="M68.9756 27.0002C68.9756 27.2516 68.783 27.4635 68.5316 27.4682C68.4922 27.4689 68.4536 27.4693 68.4157 27.4693C67.4545 27.4693 66.705 27.2359 66.1693 26.7666C65.6359 26.2973 65.3683 25.6492 65.3683 24.8228V18.5646C65.3683 18.2431 65.1077 17.9825 64.7862 17.9825H64.7503C64.4487 17.9825 64.2041 17.7379 64.2041 17.4362C64.2041 17.1346 64.4487 16.89 64.7503 16.89H64.7862C65.1077 16.89 65.3683 16.6294 65.3683 16.3079V14.5831C65.3683 14.2262 65.5915 13.9075 65.9269 13.7855C66.4804 13.5841 67.0658 13.994 67.0658 14.5831V15.9351C67.0658 16.4625 67.4933 16.89 68.0207 16.89H68.4294C68.7311 16.89 68.9756 17.1346 68.9756 17.4362C68.9756 17.7379 68.7311 17.9825 68.4294 17.9825H68.0207C67.4933 17.9825 67.0658 18.41 67.0658 18.9374V24.7749C67.0658 25.4269 67.2368 25.8883 67.5789 26.1586C67.797 26.3235 68.1106 26.436 68.5181 26.4964C68.7729 26.5342 68.9756 26.7425 68.9756 27.0002Z" />
									<path d="M79.2647 26.4518C79.2647 26.9211 78.8842 27.3016 78.4149 27.3016C77.9456 27.3016 77.5651 26.9211 77.5651 26.4518V20.5981C77.5651 19.9161 77.4086 19.329 77.0984 18.8388C76.7396 18.2839 76.241 18.0065 75.6016 18.0065C75.4426 18.0065 75.2754 18.024 75.1001 18.059C73.4225 18.3938 72.678 20.2806 72.678 21.9913V26.4518C72.678 26.9211 72.2976 27.3016 71.8283 27.3016C71.359 27.3016 70.9785 26.9211 70.9785 26.4518V11.8977C70.9785 11.5288 71.2166 11.2019 71.5679 11.0889C72.1165 10.9122 72.678 11.3214 72.678 11.8977V14.2003C72.678 15.7826 74.6568 16.8173 76.239 16.8173C77.1393 16.8173 77.8683 17.127 78.4262 17.7445C78.9861 18.3619 79.2647 19.1315 79.2647 20.0511V26.4518Z" />
									<path d="M88.2999 21.8938C88.2999 20.8032 88.0972 19.9027 87.6936 19.1902C87.214 18.322 86.4683 17.8638 85.4601 17.8165C83.597 17.9269 82.6673 19.2908 82.6673 21.9051C82.6673 23.1041 82.8609 24.1058 83.2513 24.9094C83.7495 25.9336 84.497 26.4388 85.4939 26.4218C87.3648 26.4065 88.2999 24.8978 88.2999 21.8938ZM90.1614 21.905C90.1614 23.4578 89.7726 24.7496 88.9972 25.7811C88.1435 26.9366 86.965 27.516 85.4601 27.516C83.9691 27.516 82.8069 26.9366 81.9684 25.7811C81.2076 24.7496 80.8281 23.4578 80.8281 21.905C80.8281 20.446 81.2391 19.2185 82.0612 18.2191C82.9297 17.1588 84.0713 16.627 85.4827 16.627C86.8941 16.627 88.0431 17.1588 88.9284 18.2191C89.7504 19.2187 90.1614 20.4461 90.1614 21.905Z" />
									<path d="M99.906 27.3015C98.9674 27.3015 98.2065 26.5406 98.2065 25.6019V20.2181C98.2065 19.441 97.9774 18.835 97.5201 18.3977C97.0628 17.9626 96.4529 17.7518 95.6924 17.7688C94.1358 17.798 93.388 19.4999 93.388 21.0567V26.4515C93.388 26.9208 93.0075 27.3013 92.5382 27.3013C92.0689 27.3013 91.6885 26.9208 91.6885 26.4515V21.3513C91.6885 19.4702 92.5033 17.4789 94.311 16.9583C94.3369 16.9508 94.3627 16.9436 94.3884 16.9367C95.164 16.7294 95.8483 16.627 96.4377 16.627C96.8413 16.627 97.2208 16.6667 97.5777 16.7466C98.2454 16.9043 98.7882 17.1967 99.2066 17.6262C99.6733 18.1011 99.9059 18.6712 99.9059 19.3382V27.3013C99.9059 27.3014 99.9059 27.3015 99.906 27.3015Z" />
									<path d="M20.5761 0.854847C18.8781 0.862949 17.2562 1.00943 15.8292 1.26481C11.626 2.01634 10.8628 3.5891 10.8628 6.48993C10.8628 8.60569 12.578 10.3209 14.6938 10.3209H20.158C20.5106 10.3209 20.7964 10.6067 20.7964 10.9593C20.7964 11.3119 20.5106 11.5977 20.158 11.5977H10.8628H7.13498C4.24813 11.5977 1.72029 13.3536 0.929886 16.6939C0.0177773 20.5225 -0.0228961 22.9123 0.929886 26.9098C1.63607 29.8855 3.32193 32.0059 6.2091 32.0059C8.09531 32.0059 9.62438 30.4769 9.62438 28.5907V27.4131C9.62438 24.0958 12.4609 21.1687 15.8295 21.1687H25.7512C28.5132 21.1687 30.7179 18.8678 30.7179 16.0609V6.48993C30.7179 3.76637 28.4469 1.71949 25.7512 1.26481C24.0449 0.977348 22.2741 0.846745 20.5761 0.854847ZM15.2043 3.93586C16.2304 3.93586 17.0683 4.79759 17.0683 5.857C17.0683 6.91285 16.2301 7.7668 15.2043 7.7668C14.1744 7.7668 13.3404 6.91285 13.3404 5.857C13.3404 4.79759 14.1744 3.93586 15.2043 3.93586Z" />
									<path d="M35.4113 11.4912C33.3568 11.4912 31.6913 13.1567 31.6913 15.2112V16.0285C31.6913 19.5468 28.7982 22.5073 25.4988 22.5073H15.5972C12.8849 22.5073 10.6406 24.9005 10.6406 27.7003V37.4309C10.6406 40.1996 12.9767 41.8291 15.5972 42.6231C18.7351 43.5743 21.744 43.7466 25.4988 42.6231C27.9943 41.8786 30.4554 40.3792 30.4554 37.4309C30.4554 35.2798 28.7116 33.536 26.5605 33.536H21.2026C20.8443 33.536 20.5537 33.2455 20.5537 32.8871C20.5537 32.5288 20.8443 32.2382 21.2026 32.2382H30.455H35.4113C38.2926 32.2382 39.3665 30.1669 40.3682 27.0575C41.4031 23.8561 41.3593 20.7777 40.3682 16.672C39.6561 13.7157 38.2964 11.4912 35.4113 11.4912ZM26.1227 36.1325C27.1502 36.1325 27.9828 36.9999 27.9828 38.0733C27.9828 39.1506 27.1502 40.027 26.1227 40.027C25.0986 40.027 24.2625 39.1506 24.2625 38.0733C24.2625 36.9999 25.099 36.1325 26.1227 36.1325Z" />
								</svg>
							</div>
							<div
								className={`frame-46846 ${
									selectedCode === 2 ? "selected-code" : ""
								}`}
								onClick={() => setSelectedCode(2)}
								style={{ borderRight: "none" }}
							>
								<img className="image-237" src={go} />
							</div>
						</div>
						<div className="frame-46873">
							<div className="frame-468">
								<div className="number monda-normal-white-10px">01</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">02</div>
								<div className="text-18">
									<span>
										<span className="span0">media.magic.getAI</span>
										<span className="monda-normal-white-10px">(</span>
										<span className="monda-normal-spring-green-10px">
											“FashionAI”
										</span>
										<span className="monda-normal-white-10px">);</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">03</div>
								<div className="text-19 monda-normal-white-10px">{"{"}</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">04</div>
								<div className="text-20 ">
									<span>
										<span className="monda-normal-bittersweet-10px">“id”</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="span-1">“</span>
										<span className="span3">6we4g3EQG31</span>
										<span className="span-1">”</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">05</div>
								<div className="text-21 ">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“created_at”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“2020-05-30T17:30:44”
										</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">06</div>
								<div className="user ">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“user”
										</span>
										<span className="monda-normal-white-10px">{": {"}</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">07</div>
								<div className="text-2 ">
									<span>
										<span className="monda-normal-bittersweet-10px">“id”</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="span2">“65AEG_ghp56fg”</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">08</div>
								<div className="text-2 ">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“username”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“jamesdean”
										</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">09</div>
								<div className="text-2">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“name”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“James Dean”
										</span>
										<span className="monda-normal-white-10px">;</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">10</div>
								<div className="text-2 monda-normal-white-10px">{"}"}</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">11</div>
								<div className="text-26">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“lorem_ipsum”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“dolor_sit_amet”
										</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">12</div>
								<div className="text-27">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“consectetur”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“adipiscing_elit_sed_do”
										</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
							<div className="frame-468">
								<div className="number monda-normal-white-10px">13</div>
								<div className="text-28">
									<span>
										<span className="monda-normal-bittersweet-10px">
											“eiusmod_tempor”
										</span>
										<span className="monda-normal-white-10px">: </span>
										<span className="monda-normal-spring-green-10px">
											“laboris_nisi_ut_aliquip”
										</span>
										<span className="monda-normal-white-10px">,</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="container main-features">
				{aiFilters.map((item, i) => (
					<div
						key={i}
						className={`buttontoggle ${
							activeFeature === i ? "active-btn" : ""
						}`}
						onClick={() => setActiveFeature(i)}
					>
						<div>
							<img src={item.icon} />
						</div>
						<span className="sfprodisplay-heavy-normal-white-24px">
							{item.name}
						</span>
					</div>
				))}
			</div>

			<div className="projects-container">
				<img className="hidden-sm" src={projectPreview} />
				<img src={dashboard} />
				<img className="hidden-sm" src={projectList} />
			</div>
			<div className="container" style={{ marginTop: 50 }}>
				<div className="text-5">Powerful AI + Analytics</div>
				<div className="text-29">
					Adapt, Convert, &amp; Scale Synthetic Media Efficiently
				</div>
				<div className="text-7">Use What you Know, Not Tensorflow</div>
				<div className="langs-container">
					{languages.map((item, i) => (
						<div key={i} className="lang-container">
							<img src={item.icon} />
							<div className="sfprodisplay-bold-white-20px">{item.title}</div>
						</div>
					))}
				</div>
				<div className="text-4">More coming soon....</div>
				<div className="btns-container">
					<button className="group-46976">
						<img src={graphql} />
					</button>
					<button className="group-46976">
						<img src={rest} />
					</button>
				</div>
				<div className="text-31" style={{ width: "70%", marginBlock: 60 }}>
					The MediaMagic API is a dual&nbsp;&nbsp;modern GraphQL + REST API that
					makes Synthetic Media easy.&nbsp;&nbsp;Create, Transform, and Deliver
					Fast, Simply, and Magically.
				</div>
			</div>

			<div className="overlap-group18 container">
				<div className="properities">
					<div className="property">
						<img src={simple} />
						<h2>Simple</h2>
						<p>Zero devops and configuration required</p>
					</div>
					<div className="property">
						<img src={secure} />
						<h2>Secure</h2>
						<p>Automatic HTTPS. Private by Default</p>
					</div>
					<div className="property">
						<img src={scalable} />
						<h2>Scalable</h2>
						<p>
							Guarenteed to automatically grow without interuption cost
							effectively.
						</p>
					</div>
					<div className="property">
						<img src={fast} />
						<h2>Fast</h2>
						<p>Test and view code errors right in the browser</p>
					</div>
					<div className="property">
						<img src={debug} />
						<h2>Debuggable</h2>
						<p>Test, lint, and view code errors right in the browser</p>
					</div>
					<div className="property">
						<img src={sync} />
						<h2>In Sync</h2>
						<p>
							Your medias are automatically saved and backed up in the cloud.
						</p>
					</div>
				</div>
				<div className="text-5" style={{ marginTop: 30 }}>
					One Easy API for Cutting Edge AI
				</div>
			</div>
			<div className="overlap-group18 container">
				<div className="text-5" style={{ marginBottom: 30 }}>
					Docs. Code. Magic
				</div>
				<div className="text-29">
					Leverage 100,000+ hrs of AI in minutes. No Tensorflow
				</div>
				<EmailAccess
					email={email}
					onClick={(email) => selectLang(email)}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default Landing;
