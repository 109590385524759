/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.css";
import search from "assets/common/search_icon.svg";

const SearchInput = ({ style, placeholder, fontSize, onChange }) => {
	const onChangeFunc = (e) => {
		if (onChange) onChange(e);
	};
	return (
		<div className="headersearchinput" style={style}>
			<div className="group-8671">
				<div className="group-144">
					<img className="overlap-group-12" src={search} />
				</div>
				<input
					className="search"
					name="search"
					placeholder={placeholder || "Search..."}
					type="text"
					style={{ fontSize: fontSize || 12.5 }}
					onChange={onChangeFunc}
				/>
			</div>
		</div>
	);
};

export default SearchInput;
