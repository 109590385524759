/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.css";

const Input = ({
	label,
	value,
	type,
	placeholder,
	inputStyleClass,
	onChange,
	intialValue,
	style = {},
	inputStyle = {},
	inputConStyle = {},
	icon,
	errorMsg,
	textArea
}) => {
	return (
		<div
			className={`input ${inputStyleClass ? inputStyleClass : ""}`}
			style={style}
		>
			{label && <div className="full-name inter-black-white-18px">{label}</div>}
			<div className="formsinputdefault-copy-7" style={inputConStyle}>
				<form className="overlap-group" style={inputStyle}>
					{icon && <img src={icon} />}
					{textArea ? (
						<textarea
							value={value}
							className="text"
							onChange={onChange}
							placeholder={placeholder}
							type={type || "text"}
							intialvalue={intialValue}
						/>
					) : (
						<input
							value={value}
							className="text"
							onChange={onChange}
							placeholder={placeholder}
							type={type || "text"}
							intialvalue={intialValue}
						/>
					)}
				</form>
			</div>
			{errorMsg && <span className="error-msg">**{errorMsg}**</span>}
		</div>
	);
};

export default Input;
