import video1 from "assets/article/1.mp4";
import video2 from "assets/article/2.mp4";
import video3 from "assets/article/3.mp4";
import video4 from "assets/article/4.mp4";

export const sections = [
	{
		title: "Introduction",
		time: "1:46",
		src: video1,
		rateCount: 123,
		rateVal: 5,
		like: 100,
		likeYourself: false,
		rateYourself: null
	},
	{
		title: "Background",
		time: "2:13",
		src: video2,
		rateCount: 12,
		rateVal: 4.5,
		like: 120,
		likeYourself: false,
		rateYourself: null
	},
	{
		title: "Getting Inspired",
		time: "3:05",
		src: video3,
		rateCount: 100,
		rateVal: 4.2,
		like: 300,
		likeYourself: false,
		rateYourself: null
	},
	{
		title: "Best techniques",
		time: "4:46",
		src: video4,
		rateCount: 180,
		rateVal: 4.8,
		like: 400,
		likeYourself: false,
		rateYourself: null
	},
	{
		title: "Boost conversions",
		time: "6:06",
		src: video1,
		rateCount: 400,
		rateVal: 4.9,
		like: 500,
		likeYourself: false,
		rateYourself: null
	},
	{
		title: "Get an edge",
		time: "7:12",
		src: video2,
		rateCount: 800,
		rateVal: 3.9,
		like: 800,
		likeYourself: false,
		rateYourself: null
	}
];
