import React from "react";
import {
	LineChart,
	Line,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer
} from "recharts";
import "./style.css";

const LineCharts = ({ data, lines }) => {
	const strokeWidth = 1.4;
	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart data={data}>
				<CartesianGrid stroke={"#3A3A3A"} opacity={0.4} vertical={false} />
				<YAxis
					tick={{
						fontSize: 9,
						fontFamily: "var(--font-family-sf_pro_display-regular)"
					}}
					tickFormatter={(tick) => {
						if (tick >= 1000 && tick < 1000000) return tick / 1000 + "K";
						else if (tick >= 1000000) return tick / 1000000 + "M";
						else return tick;
					}}
					interval={0}
					type="number"
				/>
				<Tooltip />
				{lines.map(
					(item, i) =>
						item.active && (
							<Line
								key={i}
								type="monotone"
								dataKey={item.key}
								stroke={item.color}
								strokeWidth={strokeWidth}
							/>
						)
				)}
			</LineChart>
		</ResponsiveContainer>
	);
};

export default LineCharts;
