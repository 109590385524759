export const content = {
	header: "Create a MediaMagic Account",
	sections: [
		{
			title: "Get started",
			p: "Here is an easy less than 10 minute tutorial to making your first Face Morph. Lets morph a clothing modeling into your favorite celebrity. Here is an easy less than 10 minute tutorial to making your first Face Morph. Lets morph a clothing modeling into your favorite celebrity."
		},
		{
			title: "What you’ll need",
			p: " • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu.\n\nNullam sodales id tortor in blandit. Morbi tellus purus, vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus faucibus."
		},
		{
			title: "1. Create your account",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		},
		{
			title: "2. Background",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		},
		{
			title: "3. Getting Inspired",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		},
		{
			title: "4. Best techniques",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		},
		{
			title: "5. Boost conversions",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		},
		{
			title: "6. Get an edge",
			p: "Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\nFusce elementum ante sed maximus viverra. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n &nbsp;1. &nbsp;Fusce elementum ante sed maximus viverra.\n&nbsp;2. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n&nbsp;3. Fusce vitae tempor purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus blandit.\n\n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. \n &nbsp; &nbsp; • &nbsp;Fusce elementum ante sed maximus viverra. Fusce vitae tempor purus.\n&nbsp; &nbsp; • &nbsp;Praesent porta erat a rhoncus blandit. Sed ac mi arcu. "
		}
	]
};

export const sections = [
	{
		title: "1. Create your account"
	},
	{
		title: "2. Background"
	},
	{
		title: "3. Getting Inspired"
	},
	{
		title: "4. Best techniques"
	},
	{
		title: "5. Boost conversions"
	},
	{
		title: "6. Get an edge"
	}
];
