/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Card, SwipableCarousal } from "components";
import "./style.css";

const ChoosePlan = () => {
    const [activeOffer, setActiveOffer] = useState(1);

    const items = [
        <Card
            key={0}
            header="FREE FOREVER"
            contentTitle="Free access to:"
            content={["Basic Docs", "Media Magic Discord chat", "Enhanced APIs", "Full Archive Data", "2 Apps"]}
            price="0"
            active={activeOffer === 0}
            setActive={() => setActiveOffer(0)}
        />,
        <Card
            key={1}
            header="COMMUNITY"
            subheader="For less than a latte or beer a month, Everything in Free, plus:"
            content={[
                "Premium Docs",
                "Coding Examples on how to get started",
                "Parity Trace and Get Debug",
                "Enhanced 2x Higher Rate Limits",
                "10 Apps"
            ]}
            beforePrice={["1,000 Octane monthly credits", "5 Photos | $1 /credit"]}
            price="7"
            active={activeOffer === 1}
            setActive={() => setActiveOffer(1)}
        />,
        <Card
            key={2}
            header="PREMIUM"
            content={[
                "Premium Support",
                "$20/hr AI Customization",
                "Enhanced APIs",
                "Full Archive Data",
                "Access to our AI Models",
                "Unlimited Apps"
            ]}
            beforePrice={["25,000 Octane monthly credits", "200 Photos | $0.50 / credit"]}
            price="79"
            active={activeOffer === 2}
            setActive={() => setActiveOffer(2)}
        />
    ];
    return (
        <div className="choose-plan">
            <div className="cards hidden-sm">{items.map(item => item)}</div>
            <div className="offers-container hidden-md">
                <SwipableCarousal items={items} setActive={val => setActiveOffer(val)} />
            </div>
        </div>
    );
};

export default ChoosePlan;
