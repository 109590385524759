/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { backgroundRemoval } from "helper/constants";
import "./style.css";
import lock from "assets/common/lock-2.svg";
import start from "assets/common/start.svg";

const BrowseAi = ({
	hideContainer,
	isMobile,
	sections,
	activeSection,
	setActiveSection,
	numbers,
	hideLowerSection,
	top
}) => {
	return (
		<div className="article-right-section" style={{ top: top || 50 }}>
			<div className="sfprodisplay-semibold-white-22px">What you’ll learn</div>
			<p className="text-7 sfprodisplay-normal-white-14px">
				You learn how to quickly and efficiently edit your media using Face
				Morphing AI.
			</p>
			<div className="sfprodisplay-medium-white-20px">Contents</div>

			{sections.map((item, i) => (
				<div
					key={i}
					className="overlap-group3 sfprodisplay-normal-white-16px"
					style={activeSection === i ? { backgroundColor: "#CB0202" } : {}}
					onClick={() => {
						setActiveSection(i);
						if (isMobile) hideContainer();
					}}
				>
					<div className="flex-container">
						<div
							style={activeSection < i ? { width: 32, height: 32 } : {}}
							className={`overlap-group-4 ${
								activeSection === i ? "active" : ""
							}`}
						>
							{numbers ? (
								i + 1
							) : (
								<img
									src={activeSection >= i ? start : lock}
									width={activeSection >= i ? "22px" : "15px"}
								/>
							)}
						</div>
						<div className="introduction valign-text-middle">{item.title}</div>
					</div>
					{item.time && (
						<div className="text-9 valign-text-middle">{item.time}</div>
					)}
				</div>
			))}

			{!hideLowerSection && (
				<div className="note-container hidden-sm">
					<div className="sfprodisplay-semibold-white-22px">
						Background Removal
					</div>
					<p className="text-7 sfprodisplay-normal-white-14px">
						How to make a subject standout and create noticable <br />
						conversions
					</p>
					<div className="overlap-group2">
						<div className="frame-47058">
							<div className="explore valign-text-middle">Explore</div>
						</div>

						<div className="group-47016">
							<img className="subtract" src={backgroundRemoval} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default BrowseAi;
