/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Table } from "components";
import { data } from "./sample-data";
import { aiFilters, aiTypes } from "helper/constants";

const Users = () => (
	<Table
		data={data}
		filters={aiFilters}
		types={aiTypes}
		headers={[
			"User ID",
			"GPU Runtime",
			"Requestor ID",
			"Submitted Date",
			"Queue Number",
			"AI Used",
			"Credits Used",
			"Time Elapsed",
			"Details"
		]}
		mobileAttributes={["gpuRuntime", "req_id", "date"]}
	/>
);

export default Users;
