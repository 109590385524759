export const enterprise = [
    "User management (SSO, provisioning API)",
    "Enterprise SLAs and support",
    "Custom contracts and invoicing",
    "Multi CDN and enterprise grade infrastructure",
    "Designated customer success manager",
    "Security and compliance review"
];

export const desktopContent = [
    {
        header: "Programmable Media",
        content:
			"Powerful image and video APIs for editing, hosting, and global content distibution for Websites and Cloud Apps."
    },
    {
        header: "Media Optimizer",
        content: "Automatically optimize image & video delivery with highest visual fidelity and best performance."
    },
    {
        header: "Dynamic Asset Management",
        content: "A single source of truth for managing the entire digital assets lifecycle."
    }
];

export const benefitTable = [
    {
        title: "Basic Docs",
        free: true,
        community: true,
        premium: true
    },
    {
        title: "Media Magic Discord chat",
        free: true,
        community: true,
        premium: true
    },
    {
        title: "Enhanced APIs",
        free: true,
        community: true,
        premium: true
    },
    {
        title: "Full Archive Data",
        free: true,
        community: true,
        premium: true
    },
    {
        title: "Priority Rendering",
        community: true,
        premium: true
    },
    {
        title: "2x Higher Rate Limits",
        community: true,
        premium: true
    },
    {
        title: "Premium Docs",
        community: true,
        premium: true
    },
    {
        title: "Coding Examples on how to get started",
        community: true,
        premium: true
    },
    {
        title: "Parity Trace and Get Debug",
        community: true,
        premium: true
    },
    {
        title: "Enhanced Support",
        community: true,
        premium: true
    },
    {
        title: "Live tutorials",
        community: true,
        premium: true
    },
    {
        title: "Submit feature requests",
        community: true,
        premium: true
    },
    {
        title: "Submit custom model",
        community: true,
        premium: true
    },
    {
        title: "$20/hr AI Customization",
        premium: true
    },
    {
        title: "Enhanced APIs",
        premium: true
    },
    {
        title: "Full Archive Data",
        premium: true
    },
    {
        title: "Access to our AI Models",
        premium: true
    },
    {
        title: "Code Access for models",
        premium: true
    },
    {
        title: "Premium Support",
        premium: true
    }
];
