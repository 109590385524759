/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.css";
const MessagePopup = ({ content, onClick }) => {
    return (
        <>
            <div className="low-opacity"></div>
            <div className="confirmation-popup">
                <p>{content}</p>
                <div>
                    <button className="popup-btn" onClick={onClick}>
					Ok
                    </button>
                </div>
            </div>
        </>
    );
};

export default MessagePopup;
