/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Filter, EditPopup } from "components";
import { useMediaQuery } from "react-responsive";
import "./style.css";
import arrowRight from "assets/common/arrow_right.svg";
import edit from "assets/common/edit.svg";
import filter from "assets/common/filter.svg";

const Table = ({ headers, data, types, filters, mobileAttributes }) => {
	const isSmallScreen = useMediaQuery({ maxWidth: 800 });
	const [hoverFilter, setHoverFilter] = useState(null);
	const [selectedFilters, setSelectedFilters] = useState(types);
	const [actualData, setActualData] = useState(null);
	const [filterVisible, setFilterVisible] = useState(false);
	const [editPopupVisible, setEditPopupVisible] = useState(false);
	const [page, setPage] = useState(1);
	const [rowNo, setRowNo] = useState(10);

	useEffect(() => {
		if (!actualData) setActualData(data);
	}, [actualData]);

	const paginationNo = Math.ceil(data.length / 10);
	const emptyArray = Array.from(Array(paginationNo).keys());

	const filterTable = (selected) => {
		let tempFilter = JSON.parse(JSON.stringify(selectedFilters));
		const index = tempFilter.indexOf(selected);
		if (index > -1) {
			tempFilter.splice(index, 1);
		} else {
			tempFilter.push(selected);
		}

		setSelectedFilters(tempFilter);

		let tempData = [];
		data.forEach((item) => {
			if (tempFilter.some((r) => item.ai_used.indexOf(r) >= 0)) {
				tempData.push(item);
			}
		});
		setActualData(tempData);
	};

	const renderTableCell = () =>
		actualData &&
		actualData.map((item, i) => (
			<tr className="overlap-group1 row" key={`user-${i}`}>
				{Object.keys(item).map((key, index) => {
					if (typeof item[key] !== "object") {
						if (isSmallScreen && [1, 2, 3].includes(index)) {
							if (index === 1) {
								return (
									<td
										key={`${i}-val-${index}`}
										className={`${
											index === 0 ? "number" : "title"
										} sfprodisplay-regular-white-10px`}
									>
										{mobileAttributes.map((attr) => item[attr] + "\n")}
									</td>
								);
							}
						} else {
							return (
								<td
									key={`${i}-val-${index}`}
									className={`${
										index === 0 ? "number" : "title"
									} sfprodisplay-regular-white-10px`}
								>
									{item[key] +
										`${
											key === "credit_used"
												? "Credits"
												: key === "time_elapsed"
												? "secs"
												: ""
										}
										`}
								</td>
							);
						}
					} else {
						return (
							<td
								key={`${i}-val-${index}`}
								className={`${
									index === 0 ? "number" : "title"
								} sfprodisplay-regular-white-10px`}
							>
								{item[key].map((aiType, aiIndex) => (
									<div
										key={`${i}-aiType-${aiIndex}`}
										className="title sfprodisplay-regular-white-10px"
									>
										{aiType}
									</div>
								))}
							</td>
						);
					}
				})}

				<td>
					<button
						className="editbutton-1"
						onClick={() => setEditPopupVisible(i + 1)}
					>
						<span className="hidden-sm">Edit </span> <img src={edit} />
					</button>
					{editPopupVisible && editPopupVisible === i + 1 && (
						<EditPopup onClick={() => setEditPopupVisible(false)} />
					)}
				</td>
			</tr>
		));

	const renderTableHead = () => {
		if (!isSmallScreen) {
			return headers.map((item, i) => (
				<th key={i} className="sfprodisplay-bold-white-12px">
					{item}
				</th>
			));
		} else {
			return headers.map((item, i) => {
				if ([1, 2, 3].includes(i)) {
					if (i === 1) {
						return (
							<th key={i} className="sfprodisplay-bold-white-12px">
								{`${headers[1]}\n${headers[2]}\n${headers[3]}`}
							</th>
						);
					}
				} else {
					return (
						<th key={i} className="sfprodisplay-bold-white-12px">
							{item}
						</th>
					);
				}
			});
		}
	};
	return (
		<div className="table">
			<div className="overlap-group19">
				<div className="flex-row-1">
					<div className="flex-container" style={{ overflow: "hidden" }}>
						<div className="filter-a-is valign-text-middle">Filter AIs:</div>
						<div className="rectangle-2455"></div>
						<div className="left-container">
							{filters.map((item, i) => (
								<div
									className={"icon"}
									onMouseEnter={() => (!isSmallScreen ? setHoverFilter(i) : {})}
									onMouseLeave={() =>
										!isSmallScreen ? setHoverFilter(null) : {}
									}
									onClick={() => filterTable(item.name)}
									key={i}
								>
									<img
										src={item.icon}
										style={{
											opacity: selectedFilters.includes(item.name) ? 1 : 0.5
										}}
									/>
									{!isSmallScreen && hoverFilter === i && (
										<div className="hover-popup">{item.name}</div>
									)}
								</div>
							))}
						</div>
						<div className="rectangle-2454"></div>
					</div>
					<div className="right-container">
						<div
							className="main-button"
							onClick={() => setFilterVisible(!filterVisible)}
						>
							<div className="btn-text">Filters</div>
							<img className="group-3" src={filter} />
						</div>
						{filterVisible && (
							<Filter onClick={() => setFilterVisible(!filterVisible)} />
						)}
						<div
							className="pagination-content hidden-sm"
							style={{ marginRight: 30, width: 120, marginLeft: 20 }}
						>
							{[50, 25, 10].map((item, i) => (
								<div
									key={i}
									onClick={() => setRowNo(item)}
									className={`${
										rowNo === item ? "active-page" : ""
									} pagination-number`}
									style={{ borderRadius: 12 }}
								>
									{item}
								</div>
							))}
						</div>
					</div>
				</div>

				<table className="flex-col-1">
					<thead>
						<tr className="overlap-group1" style={{ height: 40 }}>
							{renderTableHead()}
						</tr>
					</thead>
					<tbody>
						<tr style={{ height: 1 }} />
						{renderTableCell()}
					</tbody>
				</table>
			</div>
			{actualData && (
				<div className="pagination">
					<div className="pagination-content">
						{emptyArray.map((item, i) => (
							<div
								key={i}
								onClick={() => setPage(i + 1)}
								className={`${
									page - 1 === item ? "active-page" : ""
								} pagination-number`}
							>
								{item + 1}
							</div>
						))}

						<div
							className="main-button"
							onClick={() => (page !== paginationNo ? setPage(page + 1) : {})}
							style={{ opacity: page !== paginationNo ? 1 : 0.6 }}
						>
							<div className="btn-text hidden-sm">Next</div>
							<img className="group-3" src={arrowRight} />
						</div>
					</div>
					<div className="title-7">Page {page}</div>
					<br />
				</div>
			)}
		</div>
	);
};

export default Table;
