/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Dropdown } from "components";
import { dates } from "helper/constants";
import "./style.css";
import arrowUp from "assets/common/arrow_up.svg";
import arrowDown from "assets/common/arrow_down.svg";
import threedots from "assets/common/threedots.svg";

const SummeryContainer = ({ header, cost, margin, image, increase }) => {
    const [value, setValue] = useState("Today");
    return (
        <div className="summery-container">
            <div className="overlap-group5">
                <div className="flex-col-10">
                    <div className="sfprodisplay-bold-white-12px">{header}</div>
                    <div className="group-8746">
                        <div className="value sfprodisplay-black-white-22px">{cost}</div>
                        <div className="group-8174">
                            <div className="overlap-group-4">
                                <div className="x12k" style={{ color: !increase && "crimson" }}>
                                    {margin}
                                </div>
                                <img className="rectangle-20" src={increase ? arrowUp : arrowDown} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col-11">
                    <div className="flex-row-17">
                        <Dropdown
                            menuclassName="menuStyle"
                            items={dates}
                            value={value}
                            handleChange={e => setValue(e.target.value)}
                        />

                        <div className="group">
                            <img className="union" src={threedots} />
                        </div>
                    </div>
                    <img className="vector-9" src={image} />
                </div>
            </div>
        </div>
    );
};

export default SummeryContainer;
