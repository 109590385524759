/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { loginThirdPartyAction } from "store/actions/auth.action";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import CodeVerification from "./components/CodeVerification/CodeVerification";
import "./style.css";
import logoWord from "assets/common/logo-word.svg";

const Auth = ({ auth0 }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [component, setComponent] = useState(
		window.location.pathname.includes("register")
			? 1
			: window.location.pathname.includes("verify")
			? 2
			: 0
	);
	const { user, isAuthenticated, loginWithPopup, getAccessTokenSilently } =
		useAuth0();
	console.log(auth0); // for debugging
	useEffect(() => {
		const getUserMetadata = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				const payload = {
					email: user.email,
					name: user.name,
					picture: user.picture,
					id: user.sub
				};

				dispatch(
					loginThirdPartyAction(payload, accessToken, () => {
						history.push("/app/dashboard");
					})
				);
			} catch (e) {
				console.log(e.message);
			}
		};

		if (isAuthenticated) {
			getUserMetadata();
		}
	}, [isAuthenticated]);

	return (
		<div className="auth-container">
			<div className="left-container hidden-sm">
				<div>
					<Link to="/landing">
						<div className="brand">
							<div className="zoose">media</div>
							<img src={logoWord} />
						</div>
					</Link>
				</div>
				<div className="content-container">
					<h2>Magic for Media Made Easy.</h2>
					<p>
						Wafer dessert danish. Powder toffee cookie jelly beans bear claw
						jelly-o <br />
						gingerbread halvah. and
					</p>
				</div>
			</div>
			<div className="right-container">
				<div className="hidden-md">
					<Link to="/landing">
						<div className="brand">
							<div className="zoose">media</div>
							<img src={logoWord} />
						</div>
					</Link>
				</div>
				{component === 0 ? (
					<Login
						loginAuth0={() => loginWithPopup()}
						setComponent={(val) => setComponent(val)}
					/>
				) : component === 1 ? (
					<Register
						registerAuth0={() => loginWithPopup()}
						setComponent={(val) => setComponent(val)}
					/>
				) : (
					<CodeVerification setComponent={(val) => setComponent(val)} />
				)}
			</div>
		</div>
	);
};

export default withAuth0(Auth);
