import ai1 from "assets/browse-ai/sample-data/ai1.png";
import ai2 from "assets/browse-ai/sample-data/ai2.png";
import ai3 from "assets/browse-ai/sample-data/ai3.png";
import ai4 from "assets/browse-ai/sample-data/ai4.png";
import ai5 from "assets/browse-ai/sample-data/ai5.png";
import ai6 from "assets/browse-ai/sample-data/ai6.png";
import ai7 from "assets/browse-ai/sample-data/ai7.png";
import ai8 from "assets/browse-ai/sample-data/ai8.png";

export const data = [
    {
        title: "Face Morphing",
        content:
			"Map any selfie style media onto aother face. Morph your face onto your favorite celebrity, athlete, or map a friend’s face for a good laugh.",
        img: ai1
    },
    {
        title: "Color Swapping",
        content:
			"Jealous of another image's colors? Use our AI to steal those colors and apply them to yours instantly with only a few lines of code.",
        img: ai2
    },
    {
        title: "Facial Landmark",
        content:
			"Locate key facial points of interest. Points can be used to make adjustments to face, apply stickers, apply masks, and face track.",
        img: ai3
    },
    {
        title: "Photo -> 3D Models",
        content:
			"Tired of only 2 dimensions? Use our AI to turn any 2D photo into a 3 dimensional model of the object.",
        img: ai4
    },
    {
        title: "Background Removal",
        content: "Instantly remove the background of a media using AI. Leave it transparent or add a new background.",
        img: ai5
    },
    {
        title: "Image Blending",
        content:
			"Use our AI to merge images, stickers and other objects realistically together into one composite media.",
        img: ai6
    },
    {
        title: "File Conversion",
        content:
			"Need a file in a different format? Accurately convert any audio, video, document, ebook, archive, image, spreadsheet and presentation file.",
        img: ai7
    },
    {
        title: "AI Auto Enhance",
        content:
			"Use AI to improve media quality by enhancing details. Improve the quality for cropping, printing, and restore aging media.",
        img: ai8
    },
    {
        title: "Text Recognition",
        content:
			"Scan, Identify, and instantly get everything you need from your images, media, PDFs, scanned documents with our AI.",
        img: ai1
    }
];
