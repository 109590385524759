/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { getMediaDataAction } from "store/actions/media.action";
import { VideoModal } from "components";
import { data } from "./sample-data";
import "./style.css";
import { aiAuto, facialLandmarks, backgroundRemoval } from "helper/constants";
import arrowDown from "assets/common/arrow_down.svg";
import arrowRight from "assets/common/arrow-right.svg";
import arrowUp from "assets/common/arrow_up.svg";
import upload from "assets/common/upload_file.svg";
import filter from "assets/common/filter.svg";
import allMedia from "assets/common/all_medias.svg";
import clock from "assets/common/clock.svg";
import tagged from "assets/common/tagged_icon.svg";
import processing from "assets/common/processings_icon.svg";
import photo from "assets/common/photos_icon.svg";
import video from "assets/common/videos_icon.svg";
import threedots from "assets/common/threedots.svg";
import calendar from "assets/common/calendar_date.svg";
import diamond from "assets/common/diamond.svg";
import subtract from "assets/common/subtract.svg";
import { useDispatch, useSelector } from "react-redux";

const headerContent = [
	{ title: "All Media", icon: allMedia },
	{ title: "Recent", icon: clock },
	{ title: "Tagged", icon: tagged },
	{ title: "Processing", icon: processing },
	{ title: "Photos", icon: photo },
	{ title: "Videos", icon: video }
];
const Media = () => {
	const dispatch = useDispatch();
	const mediaData = useSelector((state) => state.media.data);
	const [activeHover, setActiveHover] = useState(null);
	const [displayedVideo, setDisplayedVideo] = useState(null);
	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		if (!mediaData) dispatch(getMediaDataAction());
	}, []);
	return (
		<div className="media">
			<div className="container">
				<div className="media-header hidden-sm">
					<div className="overlap-group-1">
						<div className="title valign-text-middle sfprodisplay-normal-white-18px">
							{headerContent[activeTab].title}
						</div>
					</div>
					<div className="flex-container">
						<div className="group-1627">
							<div className="size sfprodisplay-normal-white-14px">Size</div>
							<div className="flex-col-2">
								<img className="rectangle-18" src={arrowDown} />
								<img className="rectangle-19" src={arrowDown} />
							</div>
						</div>
						<div className="group-1628">
							<div className="upload-date sfprodisplay-normal-white-14px">
								Upload Date
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column"
								}}
							>
								<img className="rectangle-18" src={arrowUp} />
								<img className="rectangle-18" src={arrowDown} />
							</div>
						</div>
						<img className="frame-8478" src={filter} />
						<div className="frame-1009">
							<div className="upload sfprodisplay-normal-white-14px">
								Upload
							</div>
							<img className="upload-1" src={upload} />
						</div>
					</div>
				</div>
				<div className="media-header-mobile hidden-md">
					<div className="flex-row">
						{headerContent.map((item, i) => (
							<div
								key={i}
								className="overlap-group"
								style={{ filter: activeTab === i && "invert(1)" }}
								onClick={() => setActiveTab(i)}
							>
								<div className="frame-8007">
									<img className="polygon-1" src={item.icon} />
								</div>
								<div className="all-media valign-text-middle">{item.title}</div>
							</div>
						))}
					</div>
					<div className="flex-row">
						<div className="headersearchinput">
							<input id="search" placeholder="Search Media..." />
						</div>
						<img className="frame-8478" src={filter} />
					</div>
				</div>

				<div className="galleryContainer">
					{data.map((item, i) => (
						<div
							key={i}
							style={{ position: "relative" }}
							onMouseEnter={() =>
								window.innerWidth > 800 ? setActiveHover(i) : {}
							}
							onMouseLeave={() =>
								window.innerWidth > 800 ? setActiveHover(null) : {}
							}
						>
							{activeHover === i && (
								<div className="hover-container">
									<div className="top">
										<div className="img-title">{item.img_title}</div>
										<div className="img-threedots">
											<img src={threedots} />
										</div>
									</div>
									<button className="transform">
										Transform
										<img src={arrowRight} />
									</button>
									<button
										className="view"
										onClick={() => setDisplayedVideo(item)}
									>
										View
										<img src={subtract} />
									</button>
									<div className="bottom">
										<div
											style={{
												flexDirection: "column",
												alignItems: "flex-start"
											}}
										>
											<div>
												<img src={facialLandmarks} width="10px" />
												<img src={aiAuto} width="10px" />
												<img src={backgroundRemoval} width="10px" />
											</div>
											<div>
												<img src={clock} />
												<span className="img-title">9m</span>
												<img src={diamond} />
												<span className="img-title">$1,119</span>
											</div>
										</div>
										<div>
											<span className="img-title">09-08-20</span>
											<img src={calendar} />
										</div>
									</div>
								</div>
							)}
							<img
								className={`img ${activeHover === i ? "scale-up" : ""}`}
								src={item.img}
							/>
						</div>
					))}
				</div>
			</div>
			<div className="rightNav hidden-sm">
				<div className="group-1580">
					<div className="group-144">
						<div className="overlap-group-2">
							<div className="ellipse-24"></div>
						</div>
					</div>
					<div className="search sfprodisplay-regular-normal-white-14px">
						Search...
					</div>
				</div>
				{headerContent.map((item, i) => (
					<div
						key={i}
						className="group-1723"
						style={{ opacity: activeTab !== i && 0.6 }}
						onClick={() => setActiveTab(i)}
					>
						<div className="frame-8006">
							<img className="group-967" src={item.icon} />
						</div>
						<div className="all-medias valign-text-middle sfprodisplay-normal-white-14px">
							{item.title}
						</div>
					</div>
				))}
			</div>
			{displayedVideo && (
				<VideoModal
					data={displayedVideo}
					onClose={() => setDisplayedVideo(null)}
				/>
			)}
		</div>
	);
};

export default Media;
