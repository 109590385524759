import React from "react";
import { Route, Switch } from "react-router-dom";
import { Dashboard, Users, Jobs, BrowseAI, Media, AccountSettings, Article, ArticleV2 } from "pages";
import { AppHeader, AppLeftnav } from "components";
const AppRoute = () => {
    return (
        <div className="app-container">
            <AppHeader />
            <AppLeftnav />
            <Switch>
                <Route path="/app/dashboard/performance" component={Dashboard} />
                <Route path="/app/dashboard" component={Dashboard} />
                <Route path="/app/media" component={Media} />
                <Route path="/app/jobs" component={Jobs} />
                <Route path="/app/users" component={Users} />
                <Route path="/app/browse-ai" component={BrowseAI} />
                <Route path="/app/account-setting" component={AccountSettings} />
                <Route path="/app/article" component={Article} />
                <Route path="/app/article-v2" component={ArticleV2} />
            </Switch>
        </div>
    );
};

export default AppRoute;
