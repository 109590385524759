/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logoWord from "assets/common/logo-word.svg";

const footerLinks = {
	PRODUCTS: [
		{ title: "Solutions", link: "/landing/coming-soon" },
		{ title: "SDK", link: "/landing/coming-soon" },
		{ title: "AI Features", link: "/landing/coming-soon" },
		{ title: "How it works?", link: "/landing/coming-soon" }
	],
	RESOURCES: [
		{ title: "Help center", link: "/landing/coming-soon" },
		{ title: "Knowledge Base", link: "/landing/coming-soon" },
		{ title: "Launch your AI", link: "/landing/coming-soon" },
		{ title: "Learning center", link: "/landing/coming-soon" }
	],
	COMPANY: [
		{ title: "About us", link: "/landing/coming-soon" },
		{ title: "Our Team", link: "/landing/coming-soon" },
		{ title: "Contacts", link: "/landing/contact-us" }
	]
};
const MainFooter = () => {
	return (
		<div className="main-footer">
			<div className="content-container" style={{ width: "100%" }}>
				{Object.keys(footerLinks).map((key, i) => (
					<div className="flex-col-1" key={i}>
						<div className="sfprodisplay-bold-black-16px">{key}</div>
						<div className="group-7757">
							{footerLinks[key].map((item, j) => (
								<Link to={item.link} key={j}>
									<div className="solutions sfprodisplay-medium-black-20px">
										{item.title}
									</div>
								</Link>
							))}
						</div>
					</div>
				))}
			</div>
			<div className="copy-rights">
				<div className="text-16">© 2021 media AI by OneUp AI Inc.</div>
				<div className="text-16">Terms of Use &amp; Privacy Policy</div>
			</div>

			<div className="logo-container">
				<div className="zoose-1">media</div>
				<img src={logoWord} />
			</div>
		</div>
	);
};

export default MainFooter;
