import {
	LOGIN_REQUESTED,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	REGISTER_REQUESTED,
	REGISTER_FAILED,
	REGISTER_SUCCESS,
	LOGOUT,
	LISTORG_REQUESTED,
	LISTORG_SUCCESS,
	LISTORG_FAILED
} from "../types/auth.type";

const intialState = {
	user: null,
	isAuthenticated: false,
	loading: false,
	error: null,
	org: null
};

const authReducer = (state = intialState, action) => {
	switch (action.type) {
		case LOGIN_REQUESTED:
		case REGISTER_REQUESTED:
		case LISTORG_REQUESTED:
			return { ...state, loading: true };

		case LOGIN_SUCCESS:
		case REGISTER_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				user: action.payload
			};
		case LISTORG_SUCCESS:
			return {
				...state,
				loading: false,
				org: action.payload
			};
		case LOGIN_FAILED:
		case REGISTER_FAILED:
			return {
				...state,
				loading: false,
				user: null,
				error: action.payload
			};
		case LISTORG_FAILED:
			return {
				...state,
				loading: false,
				org: null,
				error: action.payload
			};
		case LOGOUT:
			return {
				...state,
				user: null,
				isAuthenticated: false
			};

		default:
			return state;
	}
};

export default authReducer;
