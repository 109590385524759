import React, { useState } from "react";
import "./style.css";

const BeforeAfterSlider = ({ before, after }) => {
	const [isDragStarted, setDragStarted] = useState(true);
	const [progress, setProgress] = useState(null);

	const containerOnMouseMove = (e) => {
		if (isDragStarted) setProgress(e.nativeEvent.offsetX);
	};

	return (
		<div
			onMouseMove={containerOnMouseMove}
			onClick={() => setDragStarted(!isDragStarted)}
			className={"images-container"}
		>
			<img className="comparing-image" src={before} alt="before" />
			<div
				style={{ width: progress }}
				className={`cd-resize-img ${isDragStarted && "resizable"}`}
			>
				<img className="comparing-image" src={after} alt="after" />
			</div>
		</div>
	);
};

export default BeforeAfterSlider;
