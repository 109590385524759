/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { mobileDatesFilter } from "helper/constants";
import "./style.css";
import SummeryContainer from "../SummeryContainer/SummeryContainer";
import flag from "assets/common/flag.svg";
import statistics from "assets/common/statistics.svg";
import arrowRight from "assets/common/arrow_right.svg";
import hide from "assets/common/hide.svg";
import dash4 from "assets/dashboard/sample-data/dash4.png";
import dash8 from "assets/dashboard/sample-data/dash8.png";
import redline from "assets/dashboard/sample-data/redline-sample.png";
import greenline from "assets/dashboard/sample-data/greenline-sample.png";
const DashboardRightTab = ({ onClick, selectedDashboard }) => {
    const [filterDate, setFilterDate] = useState(5);
    const [revealKey, setRevealKey] = useState(false);
    return (
        <div className="dashboard-right-tab">
            <div className="header-container">
                <div className="overlap-group1">
                    <div
                        className={`group-46887 ${selectedDashboard === 1 ? "selected-section" : ""}`}
                        onClick={() => onClick(1)}
                    >   
                        <img className="frame-4687" src={statistics} />
                        <div className="sfprodisplay-regular-normal-white-12px">Performance</div>   
                    </div>
                    <div
                        className={`group-46887 ${selectedDashboard === 0 ? "selected-section" : ""}`}
                        onClick={() => onClick(0)}
                    >
                        <img className="frame-4687" src={flag} />
                        <div className="sfprodisplay-regular-normal-white-12px">Getting Started</div>
                    </div>
                </div>
                <div className="hidden-md filter-dates">
                    {mobileDatesFilter.map((item, i) => (
                        <div
                            key={i}
                            style={{ opacity: filterDate === item.key ? 1 : 0.6 }}
                            onClick={() => setFilterDate(item.key)}
                        >
                            {item.value}
                        </div>
                    ))}
                </div>
            </div>
            <div className="group-46935">
                <div className="group-8204">
                    <div className="overlap-group-10">
                        <div className="flex-col-6">
                            <div className="what-to-do-with-a-fu">Getting Started</div>
                            <div className="dashboard-beta-5">Your API Keys</div>
                            <div className="flex-row-11">
                                <div className="publishable-key">Publishable key:</div>
                                <div className="text-30">absndjfj_skdxjsJshd39</div>
                            </div>
                            <div className="flex-row-12">
                                <div className="dashboard-beta-6">Secret key:</div>
                                <div className="overlap-group2">
                                    <div
                                        className="xxxxxxxxxxxxxxxxxx"
                                        style={{ filter: !revealKey ? "blur(2px)" : "blur(0px)" }}
                                    >
										xxxxxxxxxxxxxxxxxx
                                        {revealKey && (
                                            <img
                                                src={hide}
                                                style={{ marginLeft: 5, cursor: "pointer" }}
                                                onClick={() => setRevealKey(false)}
                                            />
                                        )}
                                    </div>
                                    {!revealKey && <div className="rectangle-2387"></div>}
                                    <div
                                        className="group-8154"
                                        style={{ display: revealKey ? "none" : "flex" }}
                                        onClick={() => setRevealKey(true)}
                                    >
										Reveal Key
                                    </div>
                                </div>
                            </div>
                            <div className="now-trending sfprodisplay-regular-normal-white-10px">
								Now trending topics
                            </div>
                            <div className="flex-row-13">
                                <img className="img" src={dash4} />
                                <div className="overlap-group3">
                                    <div className="overlap-group1-1">
                                        <p className="sfprodisplay-regular-normal-white-10px">
											One Day, a Machine Will
                                        </p>
                                    </div>
                                    <div
                                        className="tech sfprodisplay-regular-normal-white-10px"
                                        style={{ marginTop: 18 }}
                                    >
										#Tech
                                    </div>
                                    <div className="x19k-posts tech sfprodisplay-regular-normal-white-10px">
										1.9k posts
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-row-14">
                            <img className="img" src={dash8} />
                            <div className="flex-col-7">
                                <div className="sfprodisplay-regular-normal-white-10px">Another Windows 10 Update</div>
                                <div className="flex-row-15">
                                    <div className="windows  sfprodisplay-regular-normal-white-10px">#Windows</div>
                                    <div className="x14k-posts windows  sfprodisplay-regular-normal-white-10px">
										1.4k posts
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-8570">
                    <div className="group-7750">
                        <div className="frame-8342-1">
                            <div className="overlap-group"></div>
                        </div>
                        <div className="group-7767">
                            <div className="documentation valign-text-middle">Documentation</div>
                            <div className="api-resources">Api Resources</div>
                        </div>
                    </div>
                    <img className="group-3" src={arrowRight} />
                </div>
                <SummeryContainer header="Media Uploaded" cost="13,329" increase margin="1,2k" image={redline} />
                <SummeryContainer header="Jobs Done" cost="2,345" increase margin="1,2k" image={redline} />
                <SummeryContainer header="Media Processed" cost="1,923" margin="1,2k" image={greenline} />
            </div>
        </div>
    );
};

export default DashboardRightTab;
