/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { validators } from "helper/validator";
import { EmailAccess, MessagePopup } from "components";
import "./style.css";
import rocket from "assets/common/rocket.svg";

const ComingSoon = () => {
	const [email, setEmail] = useState("");
	const [messageContent, setMessageContent] = useState(false);

	const displayMessage = () => {
		let errorExist =
			validators.required(email, "Email is required") ||
			validators.email(email);
		setMessageContent(
			errorExist ||
				`Thank you for requesting early access: ${email} will be notified of updates!`
		);
	};
	return (
		<div className="coming-soon">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(!messageContent)}
				/>
			)}
			<div className="content-container">
				<img src={rocket} />
				<h1 className="sfprodisplay-bold-white-77px">PAGE COMING SOON</h1>
				<p className="sfprodisplay-regular-normal-white-18px">
					Page is on its way! If you want to be notified when the page goes
					life,
					<br />
					please subscribe to our mailing list below!
				</p>
				<EmailAccess
					email={email}
					onClick={(email) => displayMessage(email)}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default ComingSoon;
