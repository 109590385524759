export const individual = [
	{
		header: "Help Topic 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 2",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 3",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 4",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},

	{
		header: "Help Topic 5",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 6",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	}
];

export const business = [
	{
		header: "Help business 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help business 2",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help business 3",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},

	{
		header: "Help Topic 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help business 5",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	}
];

export const developer = [
	{
		header: "Help Topic 6",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help developer 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 3",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},

	{
		header: "Help developer 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	},
	{
		header: "Help Topic 1",
		desc: "Help topic details for the user for them to read and understand what they be reading"
	}
];

export const trendings = [
	{
		header: "GETTING STARTED",
		subHeader: "Create a MediaMagic account",
		content: "Start investing, trading, and storing cryptocurrency on Coinbase."
	},
	{
		header: "GETTING STARTED",
		subHeader: "Identity document verification",
		content:
			"Tips and steps for verifying your identitydocuments with MediaMagic."
	},
	{
		header: "GETTING STARTED",
		subHeader: "What is 2-step verification?",
		content: "Keep your account safe by adding an extra layer of security."
	},
	{
		header: "PRIVACY AND SECURITY",
		subHeader: "Avoiding cryptocurrency scams",
		content: "Keep your account safe by learning how to spot scams."
	},
	{
		header: "PRIVACY AND SECURITY",
		subHeader: "Create a MediaMagic account",
		content: "Start investing, trading, and storing cryptocurrency on Coinbase."
	},
	{
		header: "PRIVACY AND SECURITY",
		subHeader: "Create a MediaMagic account",
		content: "Start investing, trading, and storing cryptocurrency on Coinbase."
	}
];
