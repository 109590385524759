import {
	MEDIA_REQUESTED,
	GET_MEDIA_SUCCESS,
	GET_MEDIA_FAILED
} from "../types/media.type";

const intialState = {
	data: null,
	loading: false,
	error: null
};

const mediaReducer = (state = intialState, action) => {
	switch (action.type) {
		case MEDIA_REQUESTED:
			return { ...state, loading: true };

		case GET_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case GET_MEDIA_FAILED:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			};
		default:
			return state;
	}
};

export default mediaReducer;
