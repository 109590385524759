/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SearchInput, ArticleRightTab } from "components";
import { content, sections } from "./sample-data";
import "./style.css";
import sidePlayer from "assets/common/side-player.svg";

const HelpArticle = () => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	const [rightTabVisible, setRightTabVisible] = useState(false);
	const [activeSection, setActiveSection] = useState(0);
	return (
		<div className="help-article">
			{(!isMobile || rightTabVisible) && (
				<ArticleRightTab
					isMobile={isMobile}
					hideContainer={() => {
						setTimeout(() => setRightTabVisible(false), 500);
					}}
					sections={sections}
					activeSection={activeSection}
					setActiveSection={(i) => {
						setActiveSection(i);
						window.location = `/landing/help/article#${sections[i].title
							.substring(3)
							.replaceAll(" ", "-")
							.toLowerCase()}`;
					}}
					hideLowerSection
					numbers
					top={170}
				/>
			)}
			<div className="content-container">
				<SearchInput
					style={{
						borderRadius: 30,
						height: 65,
						zIndex: 99
					}}
					fontSize={20}
					placeholder="How can we help you?"
				/>
				<h3 className="sfprodisplay-bold-white-12px">{`MediaMagic Help Center > ${content.header}`}</h3>
				<h2 className="sfprodisplay-heavy-normal-white-32px">
					{content.header}
				</h2>
				<img
					className="hidden-md player"
					src={sidePlayer}
					onClick={() => setRightTabVisible(!rightTabVisible)}
				/>
				{content.sections.map((item, i) => (
					<div
						id={item.title.replaceAll(" ", "-").toLowerCase().substring(3)}
						key={i}
						className="item"
						style={{ paddingTop: 40 }}
					>
						<h2 className="sfprodisplay-normal-white-28px">{item.title}</h2>
						<p
							dangerouslySetInnerHTML={{ __html: item.p }}
							className="sfprodisplay-normal-white-18px"
						></p>
					</div>
				))}
			</div>
		</div>
	);
};

export default HelpArticle;
