import dash1 from "assets/media/sample-data/dash1.png";
import dash2 from "assets/media/sample-data/dash2.png";
import dash3 from "assets/media/sample-data/dash3.png";
import dash4 from "assets/media/sample-data/dash4.png";
import dash5 from "assets/media/sample-data/dash5.png";

export const data = [
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash1
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash2
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash3
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash5
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash3
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash2
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash5
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash2
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash5
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash1
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash2
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash5
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash1
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash4
    },
    {
        img_title: "PICTURE_TITLE.PNG",
        img: dash5
    }
];
