/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Dropdown, LineChart } from "components";
import { aiFilters, dates } from "helper/constants";
import {
	pyramidData,
	accountEarningsData,
	accountEarningsLines,
	apiUsageData,
	apiUsageLines
} from "../../sample-data";
import "./style.css";
import arrowDown from "assets/common/arrow_down.svg";
import person from "assets/common/person.svg";
import browseMedia from "assets/common/browse-media.svg";
import dataIcon from "assets/common/main-pages/data.svg";
import users from "assets/common/main-pages/users.svg";
import favUser from "assets/common/fav_user.svg";
import PyramidIcon from "./PyramidIcons";
import right from "assets/common/right.svg";
import left from "assets/common/left.svg";
import pyramidCircle from "assets/dashboard/pyramid_circle.svg";
import usage from "assets/common/usage.svg";
import progress from "assets/dashboard/progress.svg";
import arrowUp from "assets/common/arrow_up.svg";
import gold from "assets/dashboard/gold.svg";
import silver from "assets/dashboard/silver.svg";
import bronze from "assets/dashboard/bronze.svg";

const PerformanceContainer = () => {
	const [value, setValue] = useState("This Week");
	const [value2, setValue2] = useState("This Week");
	const [value3, setValue3] = useState("This Week");
	const [value4, setValue4] = useState("Style 2");
	const [activePath, setActivePath] = useState(1);
	const [hoveredPath, setHoveredPath] = useState(1);
	const [selectedAccEarnings, setSelectedAccEarnings] =
		useState(accountEarningsLines);
	const [selectedApiUsage, setSelectedApiUsage] = useState(apiUsageLines);

	const updateSelectedAccEarnings = (index) => {
		let tempSelectedAccEarnings = JSON.parse(
			JSON.stringify(selectedAccEarnings)
		);
		tempSelectedAccEarnings[index].active =
			!tempSelectedAccEarnings[index].active;
		setSelectedAccEarnings(tempSelectedAccEarnings);
	};

	const updateSelectedApiUsage = (index) => {
		let tempApiUsage = JSON.parse(JSON.stringify(selectedApiUsage));
		tempApiUsage[index].active = !tempApiUsage[index].active;
		setSelectedApiUsage(tempApiUsage);
	};
	return (
		<div className="performance-container">
			<div className="group-46904">
				<div className="metric-header-container">
					<div className="title ">Statistics</div>
					<div className="hidden-sm">
						<Dropdown
							styleclassName="group-1273"
							menuclassName="performance-menu"
							items={[
								{ key: 0, value: "Style 1" },
								{ key: 1, value: "Style 2" }
							]}
							value={value4}
							handleChange={(e) => setValue4(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex-container">
					<div className="group-46871">
						<div className="items-container">
							<div className="item-container">
								<img className="frame-46861" src={person} />
								<div className="group-47124">
									<div className="flex-col">
										<div className="title-2 sfprodisplay-normal-white-12px valign-text-middle">
											Users
										</div>
										<div className="value sfprodisplay-bold-white-18px valign-text-middle">
											{pyramidData[hoveredPath || activePath].users}K
										</div>
									</div>
									<div className="group-46854">
										<div className="number-1 valign-text-middle">535</div>
										<img className="rectangle-20-1" src={arrowUp} />
									</div>
								</div>
							</div>
							<div className="item-container">
								<img className="frame-46861" src={browseMedia} />
								<div className="group-47124">
									<div className="flex-col">
										<div className="title-2 sfprodisplay-normal-white-12px valign-text-middle">
											Total Medias
										</div>
										<div className="value sfprodisplay-bold-white-18px valign-text-middle">
											{pyramidData[hoveredPath || activePath].media}K
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="items-container">
							<div className="item-container">
								<img className="frame-46861" src={dataIcon} />
								<div className="group-47124">
									<div className="flex-col">
										<div className="title-2 sfprodisplay-normal-white-12px valign-text-middle">
											Jobs
										</div>
										<div className="value sfprodisplay-bold-white-18px valign-text-middle">
											{pyramidData[hoveredPath || activePath].jobs}
										</div>
									</div>
									<div className="group-46854">
										<div className="number-1 valign-text-middle">3</div>
										<img className="rectangle-20-1" src={arrowUp} />
									</div>
								</div>
							</div>
							<div className="item-container">
								<img className="frame-46861" src={users} />
								<div className="group-47124">
									<div className="flex-col">
										<div className="title-2 sfprodisplay-normal-white-12px valign-text-middle">
											Visitors
										</div>
										<div className="value sfprodisplay-bold-white-18px valign-text-middle">
											{pyramidData[hoveredPath || activePath].visitors}
										</div>
									</div>
									<div className="group-46854">
										<div className="number-1 red valign-text-middle">453</div>
										<img className="rectangle-20-1" src={arrowDown} />
									</div>
								</div>
							</div>
						</div>
						<div className="items-container">
							<div className="item-container">
								<img className="frame-46861" src={favUser} />
								<div className="group-47124">
									<div className="flex-col">
										<div className="title-2 sfprodisplay-normal-white-12px valign-text-middle">
											Customers
										</div>
										<div className="value sfprodisplay-bold-white-18px valign-text-middle">
											{pyramidData[hoveredPath || activePath].customers}
										</div>
									</div>
									<div className="group-46854">
										<div className="number-1 valign-text-middle">145</div>
										<img className="rectangle-20-1" src={arrowUp} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="overlap-group5-metric hidden-sm">
						<div className="overlap-group2-1">
							<div className="overlap-group1-2-metric">
								<div className="frame-7737">
									<PyramidIcon
										activePath={activePath}
										hoveredPath={hoveredPath}
										clickHandler={(val) => setActivePath(val)}
										hoverHandler={(val) => setHoveredPath(val)}
									/>
								</div>
								<img className="polygon-36" src={right} />
								<img className="polygon-37" src={left} />
							</div>
						</div>
						<div className="overlap-group3-1">
							<div className="group-1350">
								<div className="flex-row-2-metric">
									<div className="frame-46922">
										<img className="purple" src={pyramidCircle} />
									</div>
									<h1 className="value-6">
										{pyramidData[hoveredPath || activePath].title}
									</h1>
								</div>
								<div className="flex-row-3 sfprodisplay-regular-normal-white-12px">
									<div className="name-3 ">Calls</div>
									<div className="name-4 ">Expanse</div>
									<div className="name-5 ">Usage</div>
								</div>
								<div className="flex-row-4">
									<div className="amount ">
										{pyramidData[hoveredPath || activePath].calls}
									</div>
									<div className="overlap-group-6-metric">
										<div className="summary ">
											${pyramidData[hoveredPath || activePath].expenses}
										</div>
										<div className="summary-1 ">
											+{pyramidData[hoveredPath || activePath].usage}%
										</div>
									</div>
									<img className="arrow" src={arrowUp} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="group-46867 hidden-sm">
				<div className="group-46872">
					<div className="group-158">
						<div className="group-155">
							<div className="overlap-group-7">
								<img src={usage} />
							</div>
						</div>
					</div>
					<div className="group-1356">
						<div className="progress-1 sfprodisplay-normal-white-12px">
							Usage Quota
						</div>
						<div className="overlap-group-8">
							<div className="text-1">
								<span className="sfprodisplay-normal-white-12px">64.3 %</span>
							</div>
						</div>
					</div>
				</div>
				<img className="frame-8820" src={progress} />
			</div>
			<div className="overlap-group6-metric">
				<div className="flex-row-5">
					<div className="title-6 ">Account Earnings</div>
					<Dropdown
						styleclassName="group-7508-metric"
						menuclassName="performance-menu"
						items={dates}
						value={value}
						handleChange={(e) => setValue(e.target.value)}
					/>
				</div>
				<div className="overlap-group1-3">
					<LineChart data={accountEarningsData} lines={selectedAccEarnings} />
				</div>
				<div className="flex-row-6">
					{selectedAccEarnings.map((item, i) => (
						<div
							className="group-46876"
							key={i}
							onClick={() => updateSelectedAccEarnings(i)}
							style={{ opacity: item.active ? 1 : 0.4 }}
						>
							<div
								className="pick"
								style={{
									background: item.color
								}}
							></div>
							<div className="indicator sfprodisplay-medium-white-12px">
								{item.key}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="overlap-group18">
				<div className="overlap-group13">
					<div className="flex-row-5">
						<div className="title-6 ">API Usage</div>
						<Dropdown
							styleclassName="group-7508-metric"
							menuclassName="performance-menu"
							items={dates}
							value={value2}
							handleChange={(e) => setValue2(e.target.value)}
						/>
					</div>
					<div className="overlap-group1-3">
						<LineChart data={apiUsageData} lines={selectedApiUsage} />
					</div>
					<div className="flex-row-17-metric">
						{selectedApiUsage.map((item, i) => (
							<div
								key={i}
								className="overlap-group5-2"
								onClick={() => updateSelectedApiUsage(i)}
								style={{ opacity: item.active ? 1 : 0.4 }}
							>
								<div
									className="circle"
									style={{ background: item.color }}
								></div>
								<div className="face-morphing-metric sfprodisplay-bold-normal-white-9px">
									{item.title}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex-row-18">
				<div className="overlap-group17">
					<div className="overlap-group15">
						<div className="flex-row-5">
							<div className="title-6 ">Top AIs</div>
							<Dropdown
								styleclassName="group-7508-metric"
								menuclassName="performance-menu"
								items={dates}
								value={value3}
								handleChange={(e) => setValue3(e.target.value)}
							/>
						</div>
						<div className="flex-row-19">
							<div className="flex-col-12">
								<div className="administrator sfprodisplay-regular-normal-regent-gray-16px">
									№
								</div>
							</div>
							<div className="administrator-1 sfprodisplay-regular-normal-regent-gray-16px">
								Name
							</div>
							<div className="administrator-2 sfprodisplay-regular-normal-regent-gray-16px">
								Token Key
							</div>
							<div className="administrator-3 sfprodisplay-regular-normal-regent-gray-16px">
								Usage
							</div>
						</div>
						<div className="flex-row-19">
							<div className="overlap-group-19">
								<div className="name-6 sfprodisplay-regular-normal-white-12px">
									1
								</div>
								<img className="frame-4690" src={gold} />
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[0].color }}
								></div>
								<div className="face-morphing-1 sfprodisplay-medium-white-12px">
									Face Morphing
								</div>
							</div>
							<div className="name-7 sfprodisplay-regular-normal-white-16px">
								mm-live-real-duHVFfjkK01
							</div>
							<div className="name-8 sfprodisplay-regular-normal-white-16px">
								12,325
							</div>
						</div>
						<div className="flex-row-19">
							<div className="overlap-group-19">
								<div className="name-9 sfprodisplay-regular-normal-white-12px">
									2
								</div>
								<img className="frame-4690" src={silver} />
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[7].color }}
								></div>
								<div className="background-removal-1 sfprodisplay-medium-white-12px">
									Background Removal
								</div>
							</div>
							<div className="name-7 sfprodisplay-regular-normal-white-16px">
								mm-live-imagine-16gvIHF1
							</div>
							<div className="name-8 sfprodisplay-regular-normal-white-16px">
								8,184
							</div>
						</div>
						<div className="flex-row-19">
							<div className="overlap-group-19">
								<div className="name-12 sfprodisplay-regular-normal-white-12px">
									3
								</div>
								<img className="frame-4690" src={bronze} />
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[3].color }}
								></div>
								<div className="background-removal-1 sfprodisplay-medium-white-12px">
									Facial Landmark
								</div>
							</div>
							<div className="name-7 sfprodisplay-regular-normal-white-16px">
								mm-live-motion-srg2HG
							</div>
							<div className="name-8 sfprodisplay-regular-normal-white-16px">
								3,310
							</div>
						</div>
						<div className="flex-row-19">
							<div className="name overlap-group-19 sfprodisplay-regular-normal-white-12px">
								4
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[5].color }}
								></div>
								<div className="background-removal-1 sfprodisplay-medium-white-12px">
									File Conversion
								</div>
							</div>
							<div className="name-7 sfprodisplay-regular-normal-white-16px">
								mm-live-weight-3A54GEng
							</div>
							<div className="name-8 sfprodisplay-regular-normal-white-16px">
								1,928
							</div>
						</div>
						<div className="flex-row-19">
							<div className="name sfprodisplay-regular-normal-white-12px overlap-group-19">
								5
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[2].color }}
								></div>
								<div className="background-removal-1 sfprodisplay-medium-white-12px">
									OCR
								</div>
							</div>
							<div
								className="
                      name-7
                      
                      sfprodisplay-regular-normal-white-16px
                    "
							>
								mm-live-weight-3A54GEng
							</div>
							<div
								className="
                      name-8
                      
                      sfprodisplay-regular-normal-white-16px
                    "
							>
								1,928
							</div>
						</div>
						<div className="flex-row-19">
							<div className="overlap-group-19">
								<div
									className="
                        name-15
                        
                        sfprodisplay-regular-normal-white-12px
                      "
								>
									6
								</div>
							</div>
							<div className="group-46882">
								<div
									className="circle"
									style={{ background: aiFilters[8].color }}
								></div>
								<div className="background-removal-1 sfprodisplay-medium-white-12px">
									Auto Enhancement
								</div>
							</div>
							<div
								className="
                      name-7
                      
                      sfprodisplay-regular-normal-white-16px
                    "
							>
								mm-live-weight-3A54GEng
							</div>
							<div
								className="
                      name-8
                      
                      sfprodisplay-regular-normal-white-16px
                    "
							>
								1,928
							</div>
						</div>
						<div className="overlap-group3-4">
							<div className="all-a-is ">ALL AIs</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PerformanceContainer;
