export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REGISTER_REQUESTED = "LOGIN_REQUESTED";
export const REGISTER_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_FAILED = "LOGIN_FAILED";
export const CREATEAPP_REQUESTED = "CREATEAPP_REQUESTED";
export const CREATEAPP_SUCCESS = "CREATEAPP_SUCCESS";
export const CREATEAPP_FAILED = "CREATEAPP_FAILED";
export const LISTORG_REQUESTED = "LISTORG_REQUESTED";
export const LISTORG_SUCCESS = "LISTORG_SUCCESS";
export const LISTORG_FAILED = "LISTORG_FAILED";
export const LOGOUT = "LOGOUT";
