/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import arrowRight from "assets/common/arrow-right.svg";
import logoWord from "assets/common/logo-word.svg";
import headerMobile from "assets/common/header_mobile_icon.svg";

const pages = [
    {
        name: "Docs",
        link: "/landing/coming-soon"
    },
    {
        name: "Products",
        link: "/landing/coming-soon"
    },
    {
        name: "Pricing",
        link: "/landing/pricing"
    },
    {
        name: "Help",
        link: "/landing/help"
    },
    {
        name: "About Us",
        link: "/landing/coming-soon"
    },
    {
        name: "Contact Us",
        link: "/landing/contact-us"
    }
];
const MainHeader = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    return (
        <div className="marketingheader">
            <div className="item-container">
                <Link to="/landing">
                    <div className="item">
                        <div className="zoose">media</div>
                        <img src={logoWord} />
                    </div>
                </Link>
                {pages.map((item, i) => (
                    <Link key={i} to={item.link} className="hidden-sm">
                        <div className="item  sfprodisplay-bold-white-16px">{item.name}</div>
                    </Link>
                ))}
            </div>
            <div className="hidden-sm">
                <Link to="/landing/coming-soon">
                    <div className="headereditorbutton">
                        <div className="try-editor ">Try Editor</div>
                    </div>
                </Link>
                <Link to="/auth">
                    <div className="headerloginbutton">
                        <div className="login ">Login</div>
                        <img src={arrowRight} />
                    </div>
                </Link>
            </div>
            <div className="hidden-md" onClick={() => setDropdownVisible(!dropdownVisible)}>
                <img src={headerMobile} />
            </div>

            {dropdownVisible && (
                <div className="mobile-landing-menu">
                    <div className="overlap-group2">
                        <div className="overlap-group">
                            <img className="ai" src={logoWord} />
                            <div className="zoose nicomoji-regular-normal-white-20-3px">media</div>
                        </div>

                        <div className="closemodalbutton" onClick={() => setDropdownVisible(false)}>
                            <div className="overlap-group1">
                                <div className="rectangle-2285"></div>
                                <div className="rectangle-2286"></div>
                            </div>
                        </div>
                    </div>
                    {pages.map((item, i) => (
                        <Link key={i} to={item.link}>
                            <div className="sfprodisplay-bold-white-18px" onClick={() => setDropdownVisible(false)}>
                                {item.name}
                            </div>
                        </Link>
                    ))}
                    <div className="rectangle-2371"></div>
                    <Link to="/auth">
                        <div className="headerloginbutton">
                            <div className="login">Login</div>
                            <img className="line-34" src={arrowRight} />
                        </div>
                    </Link>
                    <div className="group-8436">
                        <div className="overlap-group-1">
                            <Link to="/auth/register">
                                <div className="place ">Register</div>
                            </Link>
                            <Link to="/auth/register">
                                <div className="text-91  sfprodisplay-bold-white-12px">Dont have an account?</div>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainHeader;
