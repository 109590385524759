/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import book from "assets/common/book.svg";
import learn from "assets/common/learn.svg";

const Item = ({ title, content, img, icon, background, smaller, updateSmallScreen }) => {
    return (
        <Link to="article-v2">
            <div className={updateSmallScreen ? "block-small" : "block"}>
                <div>
                    <div className="blockTitle">{title}</div>
                    <div className="flex-container">
                        {icon && (
                            <div
                                className="iconContainer"
                                style={
                                    background && {
                                        background: `linear-gradient(${background.deg}, ${background.first}, ${
                                            background.last
                                        } ${background.rgb ? ", " + background.rgb : ""} )`
                                    }
                                }
                            >
                                <img className="icon" src={icon === "book" ? book : learn} />
                            </div>
                        )}
                        <p className="content sfprodisplay-regular-normal-white-10px">{content}</p>
                    </div>
                </div>
                <img src={img} className={smaller && "smaller"} />
            </div>
        </Link>
    );
};

export default Item;
