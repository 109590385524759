import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
ReactDOM.render(
	<Provider store={store}>
		<Auth0Provider
			domain="mediamagic.eu.auth0.com"
			clientId={"ya6qt8GGaloQuxBt2UsgXCrwNjGKVR1j"}
			redirectUri={window.location.origin}
		>
			<App />
		</Auth0Provider>
	</Provider>,
	document.getElementById("root")
);
