/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { validators } from "helper/validator";
import { Input } from "components";
import "./style.css";
import cards from "assets/settings/cards.svg";
import card from "assets/common/card.svg";

const AddPaymentModal = ({ cancel, add, editPayment }) => {
	const [cardNo, setCardNo] = useState("");
	const [cvv, setCvv] = useState("");
	const [expireDate, setExpireDate] = useState("2023-06");
	const [errorMsg, setErrorMsg] = useState({
		cardNo: null,
		cvv: null,
		expireDate: null
	});
	useEffect(() => {
		if (editPayment) {
			setCardNo(editPayment.cardNo);
			setCvv(editPayment.cvv);
			setExpireDate(editPayment.expireDate);
		}
	}, [editPayment]);

	const submitAddPayment = () => {
		const removedDashesCard = cardNo.replace(/-/gi, "");
		const errorObj = {
			cardNo:
				validators.required(removedDashesCard) ||
				validators.card(removedDashesCard),
			cvv: validators.required(cvv) || validators.fixedDigitsLength(cvv, 3)
		};
		setErrorMsg(errorObj);
		if (Object.values(errorObj).every((value) => !value))
			add({
				cardNo,
				cvv,
				expireDate,
				index: editPayment && editPayment.index
			});
	};
	return (
		<>
			<div className="low-opacity"></div>
			<div className="add-payment-modal">
				<div className="payment-header">
					<h1 className="title">Add Payment Card</h1>
					<img className="frame-47038" src={cards} />
				</div>
				<br />
				<Input
					value={cardNo}
					onChange={(e) => {
						// Card number without dash (-)
						const realNumber = e.target.value.replace(/-/gi, "");

						// Generate dashed number
						const dashedNumber = realNumber.match(/.{1,4}/g);
						setCardNo(dashedNumber?.join("-"));
					}}
					icon={card}
					label="Card Number"
					placeholder="1234 1234 1234 1234 "
					errorMsg={errorMsg.cardNo}
				/>
				<div className="overlap-group3" style={{ marginTop: 30 }}>
					<Input
						value={expireDate}
						inputStyleClass="expire_input"
						onChange={(e) => setExpireDate(e.target.value)}
						placeholder="MM - YYYY"
						type="month"
						label="Expiration Date"
					/>
					<Input
						value={cvv}
						onChange={(e) => setCvv(e.target.value)}
						placeholder="123"
						type="number"
						errorMsg={errorMsg.cvv}
						label="CVV / CVN"
					/>
				</div>
				<div className="btns-container">
					<button className="popup-btn" onClick={cancel}>
						CANCEL
					</button>
					<button
						className="popup-btn blue-btn"
						onClick={() => submitAddPayment()}
					>
						ADD CARD
					</button>
				</div>
			</div>
		</>
	);
};

export default AddPaymentModal;
