import { React } from "react";

const PyramidIcon = ({ hoveredPath, activePath, clickHandler, hoverHandler }) => (
    <svg
        onMouseOut={() => hoverHandler(null)}
        width="503"
        height="288"
        viewBox="0 0 503 288"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g onMouseOver={() => hoverHandler(1)} onClick={() => clickHandler(1)} filter="url(#filter0_ii_774_4104)">
            <path
                d="M251.072 0.251602L490.798 280.304H11.3457L251.072 0.251602Z"
                fill={activePath === 1 || hoveredPath === 1 ? "url(#paint1)" : "#272742"}
                onMouseOver={() => hoverHandler(1)}
                onClick={() => clickHandler(1)}
            />
        </g>
        <g onMouseOver={() => hoverHandler(2)} onClick={() => clickHandler(2)} filter="url(#filter2_di_774_4104)">
            <path
                d="M251.072 43.2393L490.798 280.305H11.3457L251.072 43.2393Z"
                fill={activePath === 2 || hoveredPath === 2 ? "url(#paint2)" : "#23233E"}
                onMouseOver={() => hoverHandler(2)}
                onClick={() => clickHandler(2)}
            />
        </g>
        <g onMouseOver={() => hoverHandler(3)} onClick={() => clickHandler(3)} filter="url(#filter3_ii_774_4104)">
            <path
                d="M251.048 87.7589L490.797 280.302H11.2988L251.048 87.7589Z"
                fill={activePath === 3 || hoveredPath === 3 ? "url(#paint3)" : "#23233E"}
                onMouseOver={() => hoverHandler(3)}
                onClick={() => clickHandler(3)}
            />
        </g>
        <g onMouseOver={() => hoverHandler(4)} onClick={() => clickHandler(4)} filter="url(#filter4_ii_774_4104)">
            <path
                d="M251.072 133.755L490.798 280.304H11.3457L251.072 133.755Z"
                fill={activePath === 4 || hoveredPath === 4 ? "#FF03F5" : "#1C1C30"}
                onMouseOver={() => hoverHandler(4)}
                onClick={() => clickHandler(4)}
            />
        </g>
        <g onMouseOver={() => hoverHandler(5)} onClick={() => clickHandler(5)} filter="url(#filter5_ii_774_4104)">
            <path
                d="M251.072 182.688L490.798 280.303H11.3457L251.072 182.688Z"
                fill={activePath === 5 || hoveredPath === 5 ? "#F493C1" : "#19192C"}
                onMouseOver={() => hoverHandler(5)}
                onClick={() => clickHandler(5)}
            />
        </g>
        <g onMouseOver={() => hoverHandler(6)} onClick={() => clickHandler(6)} filter="url(#filter6_i_774_4104)">
            <path
                d="M251.072 225.597L490.798 280.304H11.3457L251.072 225.597Z"
                fill={activePath === 6 || hoveredPath === 6 ? "#FFA651" : "#19192C"}
                onMouseOver={() => hoverHandler(6)}
                onClick={() => clickHandler(6)}
            />
        </g>
        <defs>
            <filter
                id="filter0_ii_774_4104"
                x="11.3457"
                y="0.251602"
                width="479.453"
                height="286.972"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6.91937" />
                <feGaussianBlur stdDeviation="6.53496" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.241528 0 0 0 0 0.241528 0 0 0 0 0.391667 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.365 0 0 0 0 0.365 0 0 0 0 0.6 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_innerShadow_774_4104" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter1_ii_774_4104"
                x="11.3457"
                y="43.2383"
                width="479.32"
                height="242.448"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="5.38173" />
                <feGaussianBlur stdDeviation="9.99465" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.23125 0 0 0 0 0.23125 0 0 0 0 0.375 0 0 0 0.85 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.238264 0 0 0 0 0.238264 0 0 0 0 0.391667 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_innerShadow_774_4104" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter2_di_774_4104"
                x="4.42633"
                y="27.8629"
                width="493.292"
                height="252.442"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="-8.45701" />
                <feGaussianBlur stdDeviation="3.45969" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0324653 0 0 0 0 0.102523 0 0 0 0 0.229167 0 0 0 0.23 0"
                />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_774_4104" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_774_4104" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.11 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter3_ii_774_4104"
                x="11.2988"
                y="87.7589"
                width="479.498"
                height="197.925"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="5.38173" />
                <feGaussianBlur stdDeviation="9.99465" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.23125 0 0 0 0 0.23125 0 0 0 0 0.375 0 0 0 0.85 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.238264 0 0 0 0 0.238264 0 0 0 0 0.391667 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_innerShadow_774_4104" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter4_ii_774_4104"
                x="11.3457"
                y="133.755"
                width="479.453"
                height="151.931"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="5.38173" />
                <feGaussianBlur stdDeviation="9.99465" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.23125 0 0 0 0 0.23125 0 0 0 0 0.375 0 0 0 0.85 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.238264 0 0 0 0 0.238264 0 0 0 0 0.391667 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_innerShadow_774_4104" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter5_ii_774_4104"
                x="11.3457"
                y="182.688"
                width="479.453"
                height="100.691"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="3.07528" />
                <feGaussianBlur stdDeviation="6.91937" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.199935 0 0 0 0 0.190208 0 0 0 0 0.345833 0 0 0 0.74 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.187728 0 0 0 0 0.179965 0 0 0 0 0.304167 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_innerShadow_774_4104" result="effect2_innerShadow_774_4104" />
            </filter>
            <filter
                id="filter6_i_774_4104"
                x="11.3457"
                y="225.597"
                width="479.453"
                height="54.7076"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.53764" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.137674 0 0 0 0 0.137674 0 0 0 0 0.270833 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_4104" />
            </filter>
            <linearGradient
                id="paint1"
                x1="258.266"
                y1="-20.6263"
                x2="258.266"
                y2="295.182"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.0264412" stopColor="#5018EE" />
                <stop offset="0.453125" stopColor="#3C6DEB" />
                <stop offset="0.8125" stopColor="#F493CD" />
            </linearGradient>
            <linearGradient
                id="paint2"
                x1="251.386"
                y1="12.9772"
                x2="251.386"
                y2="273.911"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.0264412" stopColor="#1829EE" />
                <stop offset="0.453125" stopColor="#93F4CD" />
                <stop offset="1" stopColor="#93F4CD" />
            </linearGradient>
            <linearGradient
                id="paint3"
                x1="250.64"
                y1="0.00463733"
                x2="257.543"
                y2="247.686"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FE4621" />
                <stop offset="0.647059" stopColor="#FF4A25" />
            </linearGradient>
        </defs>
    </svg>
);

export default PyramidIcon;
