import React from "react";
import { Route, Switch } from "react-router-dom";
import {
	Landing,
	Pricing,
	ComingSoon,
	Contact,
	Help,
	HelpArticle
} from "pages";
import { MainHeader, MainFooter } from "components";
const LandingRoute = () => {
	return (
		<div className="app-container" style={{ padding: 0, marginTop: 52 }}>
			<MainHeader />
			<Switch>
				<Route path="/landing/pricing" component={Pricing} />
				<Route path="/landing/contact-us" component={Contact} />
				<Route path="/landing/help/:slug" component={HelpArticle} />
				<Route path="/landing/help" component={Help} />
				<Route path="/landing/coming-soon" component={ComingSoon} />
				<Route path="/landing" component={Landing} />
			</Switch>
			<MainFooter />
		</div>
	);
};

export default LandingRoute;
