/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import accountInfo from "assets/common/account-info.svg";
import apiKey from "assets/common/api-keys.svg";
import teamMembers from "assets/common/team-members.svg";
import billing from "assets/common/billing.svg";
import logoutIcon from "assets/common/logout.svg";

let info = [
	{
		title: "Billing plan",
		value: "Free"
	},
	{
		title: "Role",
		value: "Administrator"
	},
	{
		title: "Email",
		value: "odrake@mail.com"
	}
];

const links = [
	{
		title: "Account Info",
		icon: accountInfo,
		to: ""
	},
	{
		title: "API Keys",
		icon: apiKey,
		to: "#api-keys"
	},
	{
		title: "Team Members",
		icon: teamMembers,
		to: "#team-members"
	},
	{
		title: "Billing",
		icon: billing,
		to: "#billing"
	}
];
const Setting = ({ onClick }) => {
	const { logout, user } = useAuth0();
	info[2].value = user.email;
	return (
		<div>
			<div className="profile-setting-dropdown">
				<div className="overlap-group3">
					<div className="home-dashboard-alp">{user.name.substring(0, 25)}</div>
					<div className="vector-17"></div>
					{info.map((item, i) => (
						<div className="overlap-group" key={i}>
							<div className="administrator sfprodisplay-normal-white-14px">
								{item.title}
							</div>
							<div className="administrator-1 sfprodisplay-normal-white-14px">
								{item.value}
							</div>
						</div>
					))}

					<div className="vector-17"></div>
					<div className="overlap-group">
						<div className="administrator sfprodisplay-normal-white-16px">
							Settings
						</div>
					</div>
					{links.map((item, i) => (
						<Link
							key={i}
							to={`account-setting${item.to}`}
							onClick={onClick}
							className="group-8049"
						>
							<img className="vector" src={item.icon} />
							<div className="dashboard-gamma-2 sfprodisplay-normal-white-16px">
								{item.title}
							</div>
						</Link>
					))}

					<div className="vector-17"></div>

					<div
						className="group-8326"
						onClick={() => logout({ returnTo: window.location.origin })}
					>
						<img src={logoutIcon} />
						<div
							className="sfprodisplay-normal-white-18px"
							style={{ marginLeft: 10 }}
						>
							Logout
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Setting;
