/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { validators } from "helper/validator";
import { Input } from "components";
import "./style.css";
import arrowRight from "assets/common/arrow_right.svg";
import arrowLeft from "assets/common/left_arrow.svg";

const CodeVerification = ({ setComponent }) => {
	const history = useHistory();
	const [verifyCode, setVerifyCode] = useState("");
	const [errorMsg, setErrorMsg] = useState({ verifyCode: null });
	const verify = () => {
		const errorObj = {
			verifyCode:
				validators.required(verifyCode) ||
				validators.fixedDigitsLength(verifyCode, 6)
		};
		setErrorMsg(errorObj);

		if (Object.values(errorObj).every((value) => !value))
			history.push("/signin-flow");
	};
	return (
		<div className="code-verification">
			<h2 className="sfprodisplay-heavy-normal-white-24px">Enter code</h2>
			<p className="sfprodisplay-normal-white-12px">
				a 6 digit code has been sent to your email. Retrieve the code and enter
				it below to access your account.
			</p>

			<Input
				placeholder="Registeration Code..."
				onChange={(e) => setVerifyCode(e.target.value)}
				errorMsg={errorMsg.verifyCode}
				value={verifyCode}
			/>
			<div className="btns-container">
				<button className="btn white-btn" onClick={() => setComponent(1)}>
					<img src={arrowLeft} style={{ left: 10 }} />
					<p className="hidden-sm">Back</p>
				</button>
				<button className="btn red-btn" onClick={() => verify()}>
					<p>Confirm Code</p>
					<img src={arrowRight} style={{ right: 10 }} />
				</button>
			</div>
		</div>
	);
};

export default CodeVerification;
