import axios from "axios";
import { errorResponseMessage } from "helper/utils";
import { nanoid } from "nanoid";
import { Store } from "tough-cookie";
axios.defaults.baseURL = "https://mediamagic.dev/api/v1/";

axios.interceptors.request.use(
	(config) => {
		const { headers } = config;
		headers["Accept"] = "*/*";
		headers["Content-Type"] = "application/mediamagic.rest.v1+json";
		return config;
	},
	(err) => Promise.reject(err)
);
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.data?.error === "not_signed_in") {
			Store.dispatch({ type: "LOGOUT" });
		} else {
			const toastId = nanoid();
			console.error(error);

			Store.dispatch({
				type: "SET_TOAST",
				payload: {
					toastId,
					status: "error",
					message: errorResponseMessage(error?.response?.data || error)
				}
			});
			setTimeout(() => {
				Store.dispatch({ type: "REMOVE_TOAST", payload: toastId });
			}, 5000);
		}

		return Promise.reject(error);
	}
);

const http = axios;

export default http;
