import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "./reducers/auth.reducer";
import dashboardReducer from "./reducers/dashboard.reducer";
import browseAiReducer from "./reducers/browseai.reducer";
import mediaReducer from "./reducers/media.reducer";
import jobsReducer from "./reducers/jobs.reducer";
import usersReducer from "./reducers/users.reducer";

const rootReducer = combineReducers({
	auth: authReducer,
	dashboard: dashboardReducer,
	browseAi: browseAiReducer,
	media: mediaReducer,
	jobs: jobsReducer,
	users: usersReducer
});

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk))
);
