import faceMorphing from "assets/common/main-icons/face_morphing.png";
import colorSwapping from "assets/common/main-icons/color_swapping.png";
import facialLandmarks from "assets/common/main-icons/facial_landmarks.png";
import photoModel from "assets/common/main-icons/photo_model.png";
import backgroundRemoval from "assets/common/main-icons/background_removal.png";
import imageBlending from "assets/common/main-icons/image_blending.png";
import fileConversion from "assets/common/main-icons/file_conversion.png";
import aiAuto from "assets/common/main-icons/ai_auto.png";
import textRecognition from "assets/common/main-icons/text_recognition.png";

const aiTypes = [
    "Face Morphing",
    "Color Swapping",
    "Text Recognition",
    "Facial Landmarks",
    "Image Blending",
    "File Conversion",
    "Photo 3D Model",
    "Background Removal",
    "AI Auto Enhance"
];

const aiFilters = [
    {
        name: "Face Morphing",
        icon: faceMorphing,
        color: "linear-gradient(94.51deg, #A3A5FA 1.82%, #6466DF 68.67%)",
        desc: "Map any selfie style media onto another face."
    },
    {
        name: "Color Swapping",
        icon: colorSwapping,
        color: "linear-gradient(180deg,#ffdc84 0%,#f1ff52 100%)",
        desc: "Swap colors from one media and apply them to a second"
    },
    {
        name: "Text Recognition",
        icon: textRecognition,
        color: "linear-gradient(94.51deg,#8feec4 1.82%,#21d398 68.67%)",
        desc: "Scan and identify text in images, media, docs and PDFs "
    },
    {
        name: "Facial Landmarks",
        icon: facialLandmarks,
        color: "linear-gradient(94.51deg,#eebc8f 1.82%,#ff8a00 68.67%)",
        desc: "Locate key facial points for editting and tracking"
    },

    {
        name: "Image Blending",
        icon: imageBlending,
        color: "linear-gradient(152.3deg,#fa00ff 10.49%,#8a0c8c 106.96%,rgba(255, 255, 255, 0) 106.96%)",
        desc: "Merge images, stickers and other objects together"
    },

    {
        name: "File Conversion",
        icon: fileConversion,
        color: "linear-gradient(94.51deg,#91ee8f 1.82%,#30d321 68.67%)",
        desc: "Accurately convert any file(s) from one format to another"
    },
    {
        name: "Photo 3D Model",
        icon: photoModel,
        color: "linear-gradient(180deg,#fc3030 0%,#ff52ba 100%)",
        desc: "Turn any 2D photo into a 3-Dimensional model"
    },
    {
        name: "Background Removal",
        icon: backgroundRemoval,
        color: "linear-gradient(94.51deg,#8fe8ee 1.82%,#09d3ff 68.67%)",
        desc: "Remove the background to create a transparency"
    },
    {
        name: "AI Auto Enhance",
        icon: aiAuto,
        color: "linear-gradient(315.39deg,#1829ee 0%,#7a85ff 114.85%)",
        desc: "Improve any media’s quality, details, and increase resolution"
    }
];

const dates = [
    {
        key: 0,
        value: "Today"
    },
    {
        key: 1,
        value: "This Week"
    },
    {
        key: 2,
        value: "Last 4 weeks"
    },
    {
        key: 3,
        value: "Last 3 months"
    },

    {
        key: 4,
        value: "Last 12 months"
    },
    {
        key: 5,
        value: "Month to date"
    },
    {
        key: 6,
        value: "Quarter to date"
    },
    {
        key: 7,
        value: "Year to date"
    },
    {
        key: 8,
        value: "All time"
    }
];

const mobileDatesFilter = [
    {
        key: 0,
        value: "1D"
    },
    {
        key: 1,
        value: "3D"
    },
    {
        key: 2,
        value: "1W"
    },
    {
        key: 3,
        value: "1M"
    },
    {
        key: 4,
        value: "YTD"
    },
    {
        key: 5,
        value: "ALL"
    }
];
export {
    faceMorphing,
    colorSwapping,
    facialLandmarks,
    photoModel,
    backgroundRemoval,
    imageBlending,
    fileConversion,
    aiAuto,
    textRecognition,
    aiFilters,
    aiTypes,
    dates,
    mobileDatesFilter
};
