import http from "config/http";
import {
	MEDIA_REQUESTED,
	GET_MEDIA_FAILED,
	GET_MEDIA_SUCCESS
} from "../types/media.type";

export const getMediaDataAction = (callback) => async (dispatch) => {
	dispatch({ type: MEDIA_REQUESTED });
	const token = localStorage.getItem("session");
	try {
		const payload = await http
			.get("media", { headers: { Authorization: `Bearer ${token}` } })
			.then((res) => res.data);
		dispatch({ type: GET_MEDIA_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: GET_MEDIA_FAILED, payload: err?.response?.data });
	}
};
