/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Input } from "components";
import "./style.css";

const CreateApp = ({ setTeamName, setProjectName, errorMsg }) => {
	return (
		<div className="create-app">
			<h2 className="sfprodisplay-heavy-normal-white-28px">
				Name your First App
			</h2>

			<Input
				label="Team Name"
				inputStyleClass="firstInput"
				placeholder="Team Name..."
				onChange={(e) => setTeamName(e.target.value)}
				errorMsg={errorMsg.teamName}
			/>
			<Input
				label="Project Name"
				placeholder="Project Name..."
				onChange={(e) => setProjectName(e.target.value)}
				errorMsg={errorMsg.projectName}
			/>
		</div>
	);
};

export default CreateApp;
